import { Course } from "../utils/CourseClass";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { UserAuth } from "../context/AuthContext";
import { User } from "../utils/UserClass";
import { Link, useNavigate } from "react-router-dom";
import "../enroll.css";
import Notification from "./Notification";

interface Props {
  course: Course;
  lang: any;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function EnrollCourseComponent(props: Props) {
  const course = props.course;
  const lang = props.lang;
  const [isEnrolled, setIsEnrolled] = useState(false);
  const navigate = useNavigate();
  const [_, setShowNotification] = useState(false);
  let { courses } = UserAuth();

  const { user } = UserAuth();
  const { userCourses, setUserCourses } = UserAuth();

  useEffect(() => {
    for (let i = 0; i < userCourses.length; i++) {
      if (userCourses[i].meta.courseID === props.course.meta.courseID) {
        setIsEnrolled(true);
      }
    }
  }, []);
  const filteredCourses = courses.filter((courses: any) => {
    // Check some condition here, e.g., course meets certain criteria
    // and return true if it should be included in the filtered array
    for (let i = 0; i < courses.withLanguages.length; i++) {
      for (let j = 0; j < courses.withLanguages[i].lessons.length; j++) {
        return courses.withLanguages[i].lessons[j].lessonType !== "Audio";
      }
    }
    // Change this condition as needed
  });
  function enrollCourse() {
    if (isEnrolled === true) {
    } else {
      if (course.withLanguages[0].lessons[0].lessonType !== "Audio") {
        setShowNotification(true);
        user.enrollUserCourse(course.meta.courseID);

        userCourses.push(course);
        setUserCourses(userCourses);
      }
    }
    navigate(`/dashboard/my-courses/` + lang + `/` + course.meta.courseID);
  }

  function refresh() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  function generateRandomLinearGradient() {
    const colors = [
      "#9CC0AC",
      "#82bda9",
      "#83accf",
      "#90bad2",
      "#bdb0b6",
      "#cf9380",
      "#83bda9",
    ];
    const color1 = colors[getRandomInt(0, colors.length - 1)];
    const color2 = colors[getRandomInt(0, colors.length - 1)];

    return `linear-gradient(232deg, ${color1} 0%, ${color2} 100%)`;
  }

  function addRandomVariation(color: any) {
    const variation = 20; // You can adjust this value for smaller or larger variation
    const r = getRandomInt(0, 255);
    const g = getRandomInt(0, 255);
    const b = getRandomInt(0, 255);

    return `rgba(${clamp(r)}, ${clamp(g)}, ${clamp(b)}, 0.85)`;
  }

  function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function clamp(value: any, min = 0, max = 255) {
    return Math.min(max, Math.max(min, value));
  }
  return (
    <>
      {" "}
      <div className="e-mployed-course">
        <div className="cardff">
          <div className="overlap-2">
            <img
              className="rectangle-2"
              alt="Rectangle"
              src={course.meta.previewImage}
            />
            <div className="text-wrapper-3">{course.withLanguages[0].name}</div>
            <p className="p">{course.withLanguages[0].shortDescription}</p>
          </div>
          <button onClick={() => enrollCourse()} className="framef">
            <div className="text-wrapper">
              {" "}
              {isEnrolled === true
                ? ">>> Continue your course !"
                : ">>> Enroll to course !"}{" "}
            </div>
          </button>
        </div>
        <div className="text-wrapper-5">Other Courses</div>
        <div className="cframe-1  no-scrollbar  ">
          {filteredCourses.map((course: Course) => (
            <Link
              onClick={refresh}
              to={"/dashboard/courses/en/" + course.meta.courseID}
            >
              <div key={course.meta.courseID} className=" ">
                <div
                  className="ccard "
                  style={{ background: generateRandomLinearGradient() }}
                >
                  <img
                    className="rectanglec"
                    alt="Rectangle"
                    src={course.meta.previewImage}
                  />
                  <p className="cp1">{course.withLanguages[0].name}</p>
                  <p className="cp">
                    {course.withLanguages[0].shortDescription}
                  </p>
                </div>
              </div>{" "}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
