import { Challenge } from "../../utils/LessonClass";
import { DificultySelector } from "../DificultySelector";
import e from "express";
import React, { useEffect, useState, ChangeEvent } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { UserAuth } from "../../context/AuthContext";
import { ReviewRequest } from "../../utils/ReviewRequestClass";
import { Course } from "../../utils/CourseClass";
import star from "../../img/star 1.png";
import "../../challenges.css";
import ReactPlayer from "react-player";
interface Props {
  courseID: string;
  lessonID: string;
  lessontitle: string;
  challenge: Challenge[];
  course: Course;
  hasUpload: boolean;
}

export function LessonQuizImg(props: Props) {
  let [dificulty, setDificulty] = useState<number | null>(null);
  const { user, uploadFile, getFileUrl, submitReviewRequest } = UserAuth();
  const uploadCloudUrl = "/upload-cloud.jpg";
  const exerciseImageUrl =
    "uploads/exercises/" +
    user.authObject.uid +
    "/" +
    user.currentTeacher +
    "/" +
    props.lessonID +
    "/" +
    dificulty +
    "/";

  let [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (!isSubmited) {
      getFileUrl(exerciseImageUrl)
        .then((u: any) => {
          console.log("found file");
          setIsSubmited(true);
          setUploadedUrl(u);
        })
        .catch(() => { });
    }
  }, [dificulty]);

  let [uploadedUrl, setUploadedUrl] = useState(uploadCloudUrl);
  let [file, setFile] = useState<File>();

  let initialmessage = "";

  let [submissionMessage, setSubmissionMessage] =
    useState<string>(initialmessage);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      let url = URL.createObjectURL(file);
      setUploadedUrl(url);
    }
  };

  const onSubmit = async () => {
    if (!file && submissionMessage === "") return;

    let name = "none.txt";
    if (file) {
      name = file.name;
    }

    const url = await uploadFile(file, exerciseImageUrl + name);
    setIsSubmited(true);

    if (user.currentTeacher != "default") {
      let now = new Date().toDateString();
      submitReviewRequest(
        new ReviewRequest(
          user.authObject.uid,
          user.currentTeacher,
          user.authObject.displayName +
          " submission for '" +
          props.lessontitle +
          "' of course '" +
          props.course.withLanguages[0].name +
          "' dificulty: " +
          dificulty,
          submissionMessage,
          props.course.withLanguages[0].name,
          props.lessontitle,
          url,
          false,
          now,
          ""
        )
      );
    } else {
      console.log("not submitting request");
    }
  };

  const onRemove = () => {
    setUploadedUrl(uploadCloudUrl);
  };

  let on_selected = function (d: number) {
    console.log("setting dificulty to ", d);
    setDificulty(d);
  };

  let content = (
    <>
      <div className="text-wrapper">Challenge</div>
      <p className="div">Pick a dificulty for the challenge</p>
      <DificultySelector on_selected={on_selected}></DificultySelector>
    </>
  );

  let upload = <></>;
  if (props.hasUpload) {
    let buttons = <></>;
    if (!isSubmited) {
      buttons = (
        <div>
          <div className="col-span-1">
            <input
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
            />
          </div>
          <div className=" mt-2">
            <label className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">
              Text to send to your reviewer:
            </label>
            <textarea
              onChange={(text) => {
                setSubmissionMessage(text.target.value);
              }}
              rows={6}
              id="success"
              className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
              placeholder="Your response here"
            />
            <p className="mt-2 text-sm text-green-600 dark:text-green-500">
              Please insert links if necessary
            </p>
          </div>
          <div className="col-span-1">
            <button
              onClick={onSubmit}
              type="button"
              className="mt-5 bg-yellow-300 ml-3 hover:bg-yellow-400 dark:bg-yellow-500 dark:hover:bg-yellow-600 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-25 mr-2"
            >
              Submit
            </button>
            <button
              onClick={onRemove}
              type="button"
              className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1"
            >
              Remove
            </button>
          </div>
          <div className="col-span-1"></div>
        </div>
      );
    }

    upload = (
      <div className="mt-6 border-t border-gray-100 ">
        <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
          <div></div>
          <div>
            <h1 className="text-lg font-bold text-center justify-center">
              Upload result
            </h1>

            <h3 className="text-lg ml-2 text-center justify-center">
              {" "}
              photo, video, link to social media, files that are relevant to the
              result
            </h3>
          </div>
          <div className="mt-1 flex items-center">
            {isSubmited ? (
              <a className="inline-block rounded-full" href={uploadedUrl}>
                <button className="mt-5 bg-yellow-300 ml-3 hover:bg-yellow-400 dark:bg-yellow-500 dark:hover:bg-yellow-600 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-25">
                  {" "}
                  Check your submission
                </button>
              </a>
            ) : (
              buttons
            )}
          </div>
        </div>
      </div>
    );
  }

  if (dificulty != null) {
    content = (
      <>
        <div className="scroll text-center">
          <div className="ml-40 mr-40">
            <img
              className="justify-center"
              src={props.challenge[dificulty].img}
            ></img>
          </div>

          <div className="mb-6 ml-40 mr-40">
            <div className="mt-6 border-t border-gray-100">
              <h1 className="text-center"> Achievements: </h1>

              <article className="prose lg:prose-xl">
                <ReactMarkdown
                  children={props.challenge[dificulty].achievement}
                  remarkPlugins={[remarkGfm]}
                />
              </article>
            </div>
          </div>

          <div className="mb-6 ml-40 mr-40">
            <div className="mt-6 border-t border-gray-100">
              <h1 className="text-center"> Reflection question: </h1>

              <article className="prose lg:prose-xl">
                <ReactMarkdown
                  children={props.challenge[dificulty].reflection_questions}
                  remarkPlugins={[remarkGfm]}
                />
              </article>
            </div>
          </div>

          <div className="mb-6">{upload}</div>
          <div className="ReactPlayer">
            <ReactPlayer url={props.challenge[dificulty].video} controls />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="cardcha">{content}</div>
    </>
  );
}
