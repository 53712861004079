import { ChangeEvent, useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Notification from "../components/Notification";
import "../profile.css";
import { useNavigate } from "react-router-dom";

import { Tooltip, Button, Typography } from "@material-tailwind/react";

import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const {
    user,
    logOut,
    uploadFile,
    allUsers,
    updateUser,
    userSendPasswordReset,
  } = UserAuth();

  let [error, setError] = useState("");
  let [username, setUsername] = useState(user.authObject.displayName);
  let [profileSrc, setProfileSrc] = useState(user.authObject.photoURL);
  let [showNotification, setShowNotification] = useState(false);
  let [darkMode, setDarkMode] = useState(user.preferedTheme === "dark");
  let [file, setFile] = useState<File>();
  let [currentTeacher, setCurrentTeacher] = useState(user.currentTeacher);
  let [role, setRole] = useState(user.role);
  const [lang, setLang] = useState("");

  console.log(user);
  let allRoles = ["Teacher", "Student"];
  let allLang = ["English", "Romanian"];
  const [selectedValue, setSelectedValue] = useState("Select an option");

  const handleSelect = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    let url = undefined;

    if (username.length > 40) {
      setError("Username too long");
      return;
    }

    if (username.length < 5) {
      setError("Username too short");
      return;
    }

    if (file) {
      // we dont accept files bigger than 500k
      if (file.size > 500 * 1024) {
        setError("File too big");
        return;
      }

      url = await uploadFile(file, "images/profile/" + user.authObject.uid);
    }

    if (darkMode) {
      user.preferedTheme = "dark";
    } else {
      user.preferedTheme = "light";
    }

    user.currentTeacher = currentTeacher;
    user.role = role;

    await updateUser(username, url);
    await user.saveDBUser();

    window.location.reload();
  };

  const onThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const onRemove = () => {
    setProfileSrc(user.authObject.photoURL);
  };

  const onResetPassword = (e: any) => {
    e.preventDefault();
    userSendPasswordReset();
    setShowNotification(true);
  };

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      let url = URL.createObjectURL(e.target.files[0]);
      setProfileSrc(url);
    }
  };
  const history = useNavigate();

  const goto = () => {
    let url = "";
    if (role === "Teacher") {
      window.open("https://www.youtube.com/watch?v=IxVcdhvFKFM", "_blank");

      history(url);
    } else {
      window.open("https://www.youtube.com/watch?v=v6JWKVcRyy8", "_blank");
    }
  };

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  let resetNotification = <></>;
  if (showNotification) {
    resetNotification = (
      <Notification
        on_close={() => setShowNotification(false)}
        title="Password reset email sent!"
        subtitle="Check your email."
      ></Notification>
    );
  }

  let allTeachers = [["Default", "Teacher"]].concat(
    allUsers.filter((u: any) => u[1] !== undefined && u[1] !== "Student")
  );

  let err = <></>;
  if (error !== "") {
    err = <span className="font-medium mr-40 w-96 text-red-500">{error}</span>;
  }

  return (
    <>
      <div className="profile">
        <div className="text-wrapper">Your Profile</div>

        <input
          className="frame"
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          name="first-name"
          id="first-name"
          value={username}
          autoComplete="given-name"
        ></input>
        <div className="overlap">
          <div className=" ">
            <select
              className="div-wrapper block w-full mt-1 border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
              value={currentTeacher}
              onChange={(e) => {
                setCurrentTeacher(e.target.value);
              }}
            >
              {allTeachers.map(([id, role]: any) => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="overlap-group">
          <div className="text-wrapper-2">
            <div className=" ">
              <select
                className="div-wrapper block w-full mt-1 border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                {allRoles.map((role) => (
                  <option key={role} value={role}>
                    {role === "Teacher" ? "Youth Worker" : "Participant"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <p className="p">
          This information will be displayed publicly so be careful what you
          share.
        </p>

        <div className="text-wrapper-3">Username</div>
        <div className="text-wrapper-4 grid grid-cols-2">
          <div>Youth Worker Code</div>
          <Tooltip
            placement="bottom"
            className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 "
            content={
              <div className="w-80">
                <Typography
                  color="blue-gray"
                  className="font-medium text-black"
                >
                  Youth Worker Code
                </Typography>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal opacity-80 text-black"
                >
                  Select the code given by your Youth Worker in order to have
                  your repsonses sent to him or leave Default.
                </Typography>
              </div>
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5 cursor-pointer text-blue-gray-500 ml-24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </Tooltip>
        </div>
        <div className="text-wrapper-5 grid grid-cols-2">
          Your Role
          <Tooltip
            placement="bottom"
            className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 "
            content={
              <div className="w-80">
                <Typography
                  color="blue-gray"
                  className="font-medium text-black"
                >
                  Your Role
                </Typography>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal opacity-80 text-black"
                >
                  Select your role in the platform. If Youth Worker is selected,
                  a code will be generated for you. Give your participants the
                  generated code in order to have them send their responses to
                  you.
                </Typography>
              </div>
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5 cursor-pointer text-blue-gray-500 ml-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </Tooltip>
          {role === "Teacher" ? (
            <p className="mt-2 ml-40 mt-4 text-center">
              Your Youth Worker Code:{" "}
              <span className="font-bold">{user.authObject.uid}</span>
              <p className="w-96 text-xs">
                Give Your Youth Worker Code to your Participants so you get
                their answers when they hare a Result of a Challenge - see their
                results in Review Requests
              </p>
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="text-wrapper-7">Account</div>
        <div className="text-wrapper-8">Profile Picture</div>

        {role === "Teacher" ? (
          <div className="frame-vr">
            <div className="text-wrapper-9-vr">
              <button onClick={goto}>How to for Youth Worker</button>
            </div>
          </div>
        ) : (
          <div className="frame-vr">
            <div className="text-wrapper-9-vr">
              <button onClick={goto}>How to for Participants</button>
            </div>
          </div>
        )}
        <div className="">
          <label htmlFor="user-photo">
            <img className="avatar" src={profileSrc} alt="" />
          </label>
          <input
            onChange={handleFileChange}
            accept="image/*"
            id="user-photo"
            name="user-photo"
            type="file"
            hidden
          />{" "}
        </div>

        <div className="frame-2">
          <div className="text-wrapper-9">
            <button onClick={onResetPassword}>Change Password</button>
          </div>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-9">
            <button onClick={handleSignOut}>Sign Out</button>
          </div>
        </div>
        <button className="frame-5" onClick={onSave}>
          <span className="text-wrapper-9">Save</span>
        </button>

        <div className="frame-7 bg-transparent">{err}</div>

        <Link to="/dashboard">
          <button className="frame-6">
            <span className="text-wrapper-9">Cancel</span>
          </button>
        </Link>
      </div>
    </>
  );
}
