import { ReviewRequest } from "../utils/ReviewRequestClass";
import { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { ReviewResponse } from "../utils/ReviewResponseClass";
import "../response.css";
import Notification from "./Notification";

interface Props {
  id: string;
  review: ReviewRequest;
}

export default function ReviewRequestComponent(props: Props) {
  const { submitReviewResponse, reviewResponses } = UserAuth();
  let thisResponse = reviewResponses.get(props.review.responseID);
  const [showNotification, setShowNotification] = useState(false);

  const [feedback, setFeedback] = useState(
    thisResponse ? thisResponse.message : ""
  );
  const handleSubmit = async () => {
    await ReviewRequest.markReviewed(props.id);

    let now = new Date().toDateString();
    await submitReviewResponse(
      new ReviewResponse(
        props.review.to,
        props.review.from,
        "Response for your submission for '" +
        props.review.lessonID +
        "' of course '" +
        props.review.courseID +
        "'",
        feedback,
        props.review.courseID,
        props.review.lessonID,
        props.id,
        props.review.resource,
        now
      )
    );
    //
    setShowNotification(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  let submitResponse = (
    <button
      type="button"
      className="text-wrapper-5"
      onClick={handleSubmit}
      disabled={props.review.reviewed}
    >
      Submit
    </button>
  );
  let emailNotification = <></>;
  if (showNotification) {
    emailNotification = (
      <Notification
        on_close={() => setShowNotification(false)}
        title="Review sent!"
        subtitle="The reply has been sent."
      ></Notification>
    );
  }
  return (
    <>
      <div className="response-writer">
        <div className="text-wrapper">Review Requests</div>

        <div className="cardresponse text-wrap">
          <p className="title">{props.review.title}</p>{" "}
          <textarea
            placeholder="Your review here.."
            disabled
            value={props.review.message}
            id="large-input"
            rows={5}
            className="div-wrapper1"
          >{props.review.message}</textarea>
          <textarea
            placeholder="Your review here.."
            disabled={props.review.reviewed}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            id="large-input"
            rows={5}
            className="div-wrapper"
          ></textarea>
          <div className="frame-3">
            <div className="text-wrapper-5">
              <a
                href={props.review.resource}
                download={props.review.title}
                target="_blank"
              >
                <button>Download submission</button>
              </a>
            </div>
          </div>{" "}
          <div className="frame-4">{submitResponse}</div>
        </div>
      </div>{" "}
      {emailNotification}
    </>
  );
}
