import { useParams } from "react-router-dom";
import ReviewRequestComponent from "../components/ReviewRequestComponent";
import { ReviewRequestNotFound } from "../components/ReviewRequestNotFound";

import { UserAuth } from '../context/AuthContext';

export default function ReviewRequestView() {
  let params = useParams();
  let { reviewRequests } = UserAuth();

  let id = params.id;
  let review = null;

  let content = <ReviewRequestNotFound />;

  for (const [reqId, req] of reviewRequests) {
    if (reqId === id) {
      review = req;
      break;
    }
  }

  if (review !== null) {
    content = <ReviewRequestComponent review={review} id={id!} />
  }


  return content;
}
