import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import logo from "../img/logo 1.png";
import "../register.css";

export default function Register() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (username.length > 12) {
      setError("Username too long");
      return;
    }

    if (password.length < 6) {
      setError("Password should have at least 6 characters");
      return;
    }

    try {
      await createUser(username, email, password);
      navigate("/");
    } catch (er: any) {
      if (er.code === "auth/email-already-in-use") {
        setError("Email already used");
      } else {
        setError("Unknown error. Please try again");
      }
    }
  };

  return (
    <>
      {/* <div className="flex  flex-col justify-center sm:px-6 lg:px-8">

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">
                                    Username
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(e) => setUsername(e.target.value)}
                                        id="displayName"
                                        name="displayName"
                                        type="text"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(e) => setEmail(e.target.value)}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <p className='text-red-600'>{error}</p>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Sign up
                                </button>
                            </div>
                        </form>

                        <div className="mt-6">


                        </div>
                    </div>
                </div>
            </div> */}
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="e-mployed-sign-up">
          <div className="signup-wrapper">
            <div className="signup">
              <img className="logo" alt="Logo" src={logo} />

              <input
                onChange={(e) => setUsername(e.target.value)}
                id="displayName"
                name="displayName"
                type="text"
                required
                className="frame"
              />
              <div className="text-wrapper">
                <label htmlFor="displayName">Username</label>
              </div>

              <input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="div"
              />

              <div className="text-wrapper-2">
                <label htmlFor="email">Email address</label>
              </div>
              <div className="text-wrapper-3">
                <label htmlFor="password">Password</label>
              </div>

              <input
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="frame-2"
              />
              <button type="submit" className="div-wrapper">
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
