import { Course } from "../utils/CourseClass";
import { useEffect, useState } from "react";
import LessonText from "./LessonsComponents/LessonText";
import LessonVideo from "./LessonsComponents/LessonVideo";
import LessonPDF from "./LessonsComponents/LessonPDF";
import LessonAudio from "./LessonsComponents/LessonAudio";
import LessonImage from "./LessonsComponents/LessonImage";
import { UserAuth } from "../context/AuthContext";
import LessonQuiz from "./LessonsComponents/LessonQuiz";
import { User } from "../utils/UserClass";

import "../courx.css";
import quiz from "../img/quiz-1.png";
import complete from "../img/complete-1.png";
import ch from "../img/challange-1.png";
import ReactMarkdown from "react-markdown";
import desc from "../img/desc.png";

import { LessonQuizImg } from "./LessonsComponents/LessonQuizImg";
import { QuizImgResource, QuizResource } from "../utils/LessonClass";

import Notification from "./Notification";

import { CheckIcon } from "@heroicons/react/20/solid";

interface Props {
  course: Course;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function CourseComponent(props: Props) {
  const { userCourses, userLessonsCourse, setUserLessonsCourse } = UserAuth();
  const lessons = props.course.withLanguages[0].lessons;
  const [clicked, setClicked] = useState(0);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationClosed, setNotificationClosed] = useState(false);

  const on_notification_closed = function () {
    setShowNotification(false);
    setNotificationClosed(true);
  };

  if (
    clicked === lessons.length - 1 &&
    !showNotification &&
    !notificationClosed
  ) {
    setShowNotification(true);
  }

  let finish_notification = showNotification ? (
    <Notification
      on_close={on_notification_closed}
      title={"You finished a course!"}
      subtitle={
        "The " +
        props.course.withLanguages[0].name +
        " course was added to your profile. You can retake it anytime."
      }
    />
  ) : (
    <></>
  );

  let { user } = UserAuth();
  const [lessonsCount, setLessonCount] = useState(0);
  //const [userLessonsClicked, setUserLessonsClicked] =useState<Array<string>>([]);
  let userLessonsClicked = [];

  function next() {
    if (clicked > -1) {
      //console.log('f', props.course.withLanguages[0].lessons[clicked].lessonID)
      //user.completelUserLessons( props.course.withLanguages[0].lessons[clicked].lessonID);
    }
  }

  let thisLessonAnswers = [];
  if (clicked != -1) {
    let courses = user.courses.get(user.currentTeacher);
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].courseID === props.course.meta.courseID) {
        for (let lesson of courses[i].lessonsFinished) {
          if (lesson.lessonID === lessons[clicked].lessonID) {
            thisLessonAnswers = lesson.answerID;
          }
        }

        break;
      }
    }
  }

  if (clicked > -1) {
    if (lessons[0].lessonType !== "Audio") {
      for (let i = 0; i < userLessonsCourse.length; i++) {
        if (userLessonsCourse[i].courseID === props.course.meta.courseID) {
          if (userLessonsCourse[i].lessonsFinished.length === 0) {
            user.finishedUserLessons(props.course.meta.courseID, {
              lessonID: props.course.withLanguages[0].lessons[clicked].lessonID,
              answerID: [],
            });
          }
        }
      }
    }

    if (lessons[clicked].lessonType !== "Quiz") {
      if (lessons[0].lessonType !== "Audio") {
        user.finishedUserLessons(props.course.meta.courseID, {
          lessonID: lessons[clicked].lessonID,
          answerID: [],
        });

        setUserLessonsCourse(userLessonsCourse);
      }
    }
  }

  return (
    <>
      {clicked !== -1 ? (
        <div className="course-page">
          {lessons[clicked].lessonType === "Text" ? (
            <LessonText
              key={clicked}
              description={lessons[clicked].description}
              course={props.course}
              lessontitle={lessons[clicked].title}
              hasUpload={lessons[clicked].resource.source !== "false"}
              lessonID={lessons[clicked].lessonID}
              complitionMessage={
                (lessons[clicked].resource as any).submitMessage !== undefined
                  ? (lessons[clicked].resource as any).submitMessage
                  : ""
              }
            />
          ) : lessons[clicked].lessonType === "Video" ? (
            <LessonVideo
              description={lessons[clicked].description}
              lessontitle={lessons[clicked].title}
              video={lessons[clicked].resource.source}
            />
          ) : lessons[clicked].lessonType === "PDF" ? (
            <LessonPDF
              description={lessons[clicked].description}
              lessontitle={lessons[clicked].title}
              file={lessons[clicked].resource.source}
              filename={lessons[clicked].resource.title}
            />
          ) : lessons[clicked].lessonType === "Audio" ? (
            <LessonAudio
              description={lessons[clicked].description}
              lessontitle={lessons[clicked].title}
              audio={lessons[clicked].resource.source}
            />
          ) : lessons[clicked].lessonType === "Image" ? (
            <LessonImage
              description={lessons[clicked].description}
              lessontitle={lessons[clicked].title}
              img={lessons[clicked].resource.source}
            />
          ) : lessons[clicked].lessonType === "Quiz" ? (
            <LessonQuiz
              key={clicked}
              courseID={props.course.meta.courseID}
              lessonID={lessons[clicked].lessonID}
              resource={(lessons[clicked].resource as QuizResource).quiz}
              userAnswers={thisLessonAnswers}
            />
          ) : lessons[clicked].lessonType === "QuizImg" ? (
            <LessonQuizImg
              courseID={props.course.meta.courseID}
              course={props.course}
              lessontitle={lessons[clicked].title}
              hasUpload={true}
              lessonID={lessons[clicked].lessonID}
              challenge={
                (lessons[clicked].resource as QuizImgResource).challenge
              }
            />
          ) : (
            "Nothing"
          )}
          <div className="text-wrapper-3">
            {props.course.withLanguages[0].name}
          </div>

          {clicked === 0 ? (
            <button onClick={() => setClicked(1)}>
              <div className="horizontal-card">
                <div className="quiz-wrapper">
                  <img className="quiz" alt="Quiz" src={quiz} />
                </div>
                <div className="frame-2cx">
                  <div className="text-wrapper-4">Quiz</div>
                  <div className="text-wrapper-5">Nothing too hard...</div>
                </div>
              </div>
            </button>
          ) : (
            <button onClick={() => setClicked(0)}>
              <div className="horizontal-card">
                <div className="quiz-wrapper">
                  <img className="quiz" alt="Quiz" src={desc} />
                </div>
                <div className="frame-2cx">
                  <div className="text-wrapper-4">Description</div>
                  <div className="text-wrapper-5">Whats's it all about?</div>
                </div>
              </div>
            </button>
          )}

          <button onClick={() => setClicked(2)}>
            <div className="horizontal-card-2">
              <div className="challenge-wrapper">
                <img className="challenge" alt="Challenge" src={ch} />
              </div>
              <div className="frame-2cx">
                <div className="text-wrapper-4">Challenge</div>
                <p className="text-wrapper-5">Are you up to it?</p>
              </div>
            </div>
          </button>
          <button onClick={() => setClicked(3)}>
            <div className="horizontal-card-3">
              <div className="completed-wrapper">
                <img className="completed" alt="Completed" src={complete} />
              </div>
              <div className="frame-2cx">
                <div className="text-wrapper-4">Completion</div>
                <div className="text-wrapper-5">Congratulations!</div>
              </div>
            </div>
          </button>
        </div>
      ) : (
        <div className="course-page">
          <div className="m-auto mt-10 flex items-center justify-center w-full h-64 rounded sm:w-96 dark:bg-gray-700">
            <img
              className="object-cover object-top h-full w-full"
              src={props.course.meta.previewImage}
            ></img>
          </div>

          <div className="justify-center text-center mr-2 mt-10">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => setClicked(clicked + 1)}
            >
              Start the Course
            </button>
          </div>
        </div>
      )}
    </>
  );
}
