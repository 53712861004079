import React, { useEffect, useState } from "react";

import { PaperClipIcon } from "@heroicons/react/20/solid";
import "../../quiz.css";
interface LessonPDF {
  description: string;
  lessontitle: string;
  file: string;
  filename: string;
}

function LessonPDF(props: LessonPDF) {
  return (
    <div className="cardquiz">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600"> </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {props.lessontitle}
        </h1>
        <div className="mt-10 max-w-2xl">
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      {props.filename}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href={props.file}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonPDF;
