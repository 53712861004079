import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";

export class ReviewResponse {
  from: string;
  to: string;
  title: string;
  message: string;
  courseID: string;
  lessonID: string;
  requestID: string;
  resource: string;
  createdAt: string;

  constructor(from: string, to: string, title: string, message: string, courseID: string, lessonID: string, requestID: string, resource: string, createdAt: string) {
    this.from = from;
    this.to = to;
    this.title = title;
    this.message = message;
    this.courseID = courseID;
    this.lessonID = lessonID;
    this.requestID = requestID;
    this.resource = resource;
    this.createdAt = createdAt;

  }
}

export class ReviewResponseConverter implements FirestoreDataConverter<ReviewResponse> {

  toFirestore(course: WithFieldValue<ReviewResponse>) {
    return course;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions | undefined): ReviewResponse {
    const data = snapshot.data(options);
    return new ReviewResponse(data.from, data.to, data.title, data.message, data.courseID, data.lessonID, data.requestID, data.resource, data.createdAt)
  }
}
