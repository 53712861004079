import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GithubAuthProvider,
  UserCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  getAuth,
} from "firebase/auth";
import { auth, db, storage } from "./firebase";
import Loading from "../pages/Loading";
import { User, AuthObject, instanceOfAuthObject } from "../utils/UserClass";

import {
  ReviewRequest,
  ReviewRequestConverter,
} from "../utils/ReviewRequestClass";

import {
  collection,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Course, CourseConverter } from "../utils/CourseClass";
import { faListNumeric } from "@fortawesome/free-solid-svg-icons";
import {
  ReviewResponse,
  ReviewResponseConverter,
} from "../utils/ReviewResponseClass";

const defaultProfilePicture =
  "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2Fprofile.png?alt=media&token=00ea3085-e246-403f-8126-5b78c59de339";

export type CurrentUserType = User;

const AuthContext = createContext<any>(null);

export const AuthContextProvider = (props: { children?: JSX.Element }) => {
  let [user, setUser] = useState<any>({});
  let [courses, setCourses] = useState<any>({});
  let [userCourses, setUserCourses] = useState<Course[]>([]);
  let [userCoursesReady, setUsercoursesReady] = useState<Boolean>(false);
  let [userLessonsCourse, setUserLessonsCourse] = useState<any>({});
  let [userLessonsCourseReady, setUserLessonsCourseReady] =
    useState<Boolean>(false);

  const [allUsers, setAllUsers] = useState<[string, string][]>([]);

  const [userReady, setUserReady] = useState(false);
  const [coursesReady, setCoursesReady] = useState(false);

  const [reviewRequests, setReviewRequests] = useState<
    Map<string, ReviewRequest>
  >(new Map());
  const [reviewRequestsReady, setReviewRequestsReady] = useState(false);

  const [reviewResponses, setReviewResponses] = useState<
    Map<string, ReviewResponse>
  >(new Map());
  const [reviewResponsesReady, setReviewResponsesReady] = useState(false);

  const createUser = (username: string, email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (newUser) => {
        await updateProfile(auth.currentUser!, {
          displayName: username,
        });
      }
    );
  };

  const signIn = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const uploadFile = async (file: any, path: string) => {
    const fileRef = ref(storage, path);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  const getFileUrl = async (path: string) => {
    const fileRef = ref(storage, path);
    return await getDownloadURL(fileRef);
  };

  const getReviewResponses = async () => {
    let reviewsRespRef1 = query(
      collection(db, "review_responses")
    ).withConverter(new ReviewResponseConverter());
    const review_querySnapshot = await getDocs(reviewsRespRef1);

    let revResp: Map<string, ReviewResponse> = new Map();
    for (let i = 0; i < review_querySnapshot.docs.length; i++) {
      revResp.set(
        review_querySnapshot.docs[i].id,
        review_querySnapshot.docs[i].data()
      );
    }

    setReviewResponses(revResp);
    setReviewResponsesReady(true);
  };

  const submitReviewResponse = async (rep: ReviewResponse) => {
    const raw_resp = {
      from: rep.from,
      to: rep.to,
      title: rep.title,
      message: rep.message,
      courseID: rep.courseID,
      lessonID: rep.lessonID,
      requestID: rep.requestID,
      resource: rep.resource,
      createdAt: rep.createdAt,
    };

    let docRef = await addDoc(collection(db, "review_responses"), raw_resp);
    await updateDoc(doc(db, "review_requests", rep.requestID), {
      responseID: docRef.id,
    });
  };

  const getReviewRequests = async () => {
    let reviewsReqRef1 = query(collection(db, "review_requests")).withConverter(
      new ReviewRequestConverter()
    );
    const review_querySnapshot = await getDocs(reviewsReqRef1);

    let revReqs: Map<string, ReviewRequest> = new Map();
    for (let i = 0; i < review_querySnapshot.docs.length; i++) {
      revReqs.set(
        review_querySnapshot.docs[i].id,
        review_querySnapshot.docs[i].data()
      );
    }

    setReviewRequests(revReqs);
    setReviewRequestsReady(true);
  };

  const submitReviewRequest = async (req: ReviewRequest) => {
    console.log("review request: ", req);

    const raw_req = {
      from: req.from,
      to: req.to,
      title: req.title,
      message: req.message,
      courseID: req.courseID,
      lessonID: req.lessonID,
      resource: req.resource,
      reviewed: false,
      createdAt: req.createdAt,
      responseID: req.responseID,
    };

    await addDoc(collection(db, "review_requests"), raw_req);
  };

  const updateUser = async (username?: string, photoURL?: string) => {
    let settings: any = {};
    if (username) {
      settings.displayName = username;
    }

    if (photoURL) {
      settings.photoURL = photoURL;
    }

    await updateProfile(user.authObject, settings)
      .then(() => {})
      .catch((error) => {
        console.log("Error updating: ", error);
      });

    await user.saveDBUser(true);
  };

  const getAllUsers = async () => {
    let usersRef = collection(db, "users");
    let allUsers: [string, string][] = [];
    await getDocs(usersRef)
      .then((qSnapshot) => {
        qSnapshot.forEach((doc) => {
          const data = doc.data();
          allUsers.push([data.uid, data.role]);
        });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });

    setAllUsers(allUsers);
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    //signInWithRedirect(auth, provider)
  };
  const facebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider);
    //signInWithRedirect(auth, provider)
  };

  const twitterSignIn = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider);
    //signInWithRedirect(auth, provider)
  };

  const githubSignIn = () => {
    const provider = new GithubAuthProvider();
    signInWithPopup(auth, provider);
    //signInWithRedirect(auth, provider)
  };
  const logOut = () => {
    signOut(auth);
    setUserReady(false);
  };

  const userSendEmailVerification = async () => {
    if (user !== null && user !== undefined && Object.keys(user).length !== 0) {
      await sendEmailVerification(user.authObject, null);
    }
  };

  const userSendPasswordReset = async () => {
    if (user !== null && user !== undefined && Object.keys(user).length !== 0) {
      await sendPasswordResetEmail(getAuth(), user.authObject.email);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (
        currentUser !== null &&
        currentUser !== undefined &&
        Object.keys(currentUser).length !== 0
      ) {
        if (instanceOfAuthObject(currentUser)) {
          // TODO: this should not happen very often, but we should try our best to
          // construct a user with what we get from the db, even if the information
          // is incomplete/wrong
          let user = await User.getDBUser(currentUser as AuthObject);
          if (user === null) {
            // could not construct a db user from firestore meaning that
            // the db is either corrupted or not existent.
            // Save the default user constructed from the AuthObject to the database
            console.log(
              "User non existent or corrupted. Saving the default user"
            );
            user = User.fromAuthObject(currentUser);
            await user.saveDBUser(true);
          }

          if (user.authObject.photoURL === null) {
            user.authObject.photoURL = defaultProfilePicture;
          }

          if (user.authObject.displayName === null) {
            user.authObject.displayName = user.authObject.email;
          }

          setUser(user);
        } else {
          console.log("current user is not auth user");
          // TODO: what do we do here? :)
        }
      } else {
        setUser({});
      }
      if (!userReady) {
        setTimeout(() => {
          setUserReady(true);
        }, 10);
      }
    });

    const get_courses = async () => {
      let courseRef1 = query(collection(db, "courses")).withConverter(
        new CourseConverter()
      );
      const course_querySnapshot = await getDocs(courseRef1);

      let courses = [];
      // for (let i = 0; i < course_querySnapshot.docs.length; i++) {
      //   courses.push(course_querySnapshot.docs[i].data());
      // }

      courses.push(
        {
          meta: {
            courseID: "course 2",
            category: "Self-Awareness",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fself%20awareness.png?alt=media&token=98a99a44-6466-4704-ab48-b8d1f41eeb54",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Self-Awareness",
              description: "Self-Awareness",
              shortDescription:
                "Self-awareness is the ability to recognize and understand your own thoughts, feelings, and actions.",
              lessons: [
                {
                  lessonID: "course 2 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Self-awareness is the ability to recognize and understand your own thoughts, feelings, and actions.

        It means being honest with yourself and knowing your strengths and weaknesses.
        For example, if you are feeling anxious about a presentation, you can practise self-awareness by acknowledging your emotions, identifying the source of your anxiety, and finding ways to cope with it.
        You can also ask for feedback from others and learn from your mistakes.
        By doing this, you are being self-aware and resilient.
        You are not just ignoring your feelings or blaming others for your problems. That would be self-deceptive and unproductive.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 2 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of self-awareness?",
                        answers: [
                          "An awareness of one’s own personality or individuality.",
                          "A process by which one examines one’s own thoughts, feelings, and actions.",
                          "A state of being conscious of one’s own existence and identity.",
                          "All of the above.",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "All of the above.",
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 5, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What is an example of a tool or technique that can help improve self-awareness?",
                        answers: [
                          "Keeping a journal or diary of one’s thoughts and feelings.",
                          "Taking a personality test or assessment.",
                          "Thinking about yourself a lot.",
                          "All of the above.",
                          "1 and 2",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "1 and 2",
                        points: 10,
                        explanation: "1 and 2",
                        tagsPoints: [0, 5, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question: "What are some benefits of self-awareness?",
                        answers: [
                          "It can improve emotional regulation, decision making, and interpersonal relationships.",
                          "It can increase self-confidence, self-esteem, and self-acceptance.",
                          "It can enhance creativity, learning, and problem-solving skills.",
                          "All of the above.",
                        ],
                        correctAnswer: "All of the above.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 5, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 2 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FSelf-awareness%20back.png?alt=media&token=c9b88288-a0c2-4ebe-a72f-d7d1612ca173&_gl=1*ki4ddp*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5NzczMTQyMS4xMS4xLjE2OTc3MzI1ODMuNDAuMC4w",
                        points: 5,
                        achievement: `
        1) Identify the company/organisation you want to contact
        2) Identify the position you are applying for and its requirements
        3) Research how to write a good CV
        4) Write a CV for the given position
        5) Have your teammate/somebody else give you feedback on
        your CV
        6) Present your CV to the facilitator
        7) Send the CV to the company/organisation
        `,
                        reflection_questions: `
        What are you afraid would happen when you send your CV?

        What are the fears that are holding you back in your life?
        `,
                        video:
                          "https://youtu.be/9siaTWACpKY?si=Lxkk8OxY8CrQ_N9T",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FSelf-awareness%20back%20(1).png?alt=media&token=5f3a4564-870a-4456-9a89-f8220b24d4ab&_gl=1*1ei5ldo*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5NzczMTQyMS4xMS4xLjE2OTc3MzI2NDYuNTMuMC4w",
                        points: 10,
                        achievement: `
        Every exchange is a form of achievement.
        `,
                        reflection_questions: `
        What was your vision, and have you been aligned with it during the
        Challenge?
        What was holding you back during the Challenge? Is this appearing
        in your life as well, and if so, how? What could help you to overcome
        it/use it to your advantage?
        What helped you to move forward?
        `,
                        video:
                          "https://youtu.be/N3EBeuqNTIw?si=aIPbCz8YiEXEXfYY",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FSelf-awareness%20back%20(2).png?alt=media&token=813e5d37-745c-427e-a709-752478c954b8&_gl=1*19a006o*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5NzczMTQyMS4xMS4xLjE2OTc3MzI2ODYuMTMuMC4w",
                        points: 15,
                        achievement: `
        1) Find/create your story (ie. find anything special about any of
        you, and if you don’t have anything, try to break any Guinness
        World Record)
        2) Contact the media
        3) Gain the interview/report
                              `,
                        reflection_questions: `
        What was the reaction of the media to you? What helped you to succeed?

        What is the story in your life the world deserves to know?
                              `,
                        video:
                          "https://youtu.be/oCWt8cqBRxA?si=sYOhUwW9DC2ij2_m",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 2 lesson 4",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 4",
            category: "Communication",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fcommunication.png?alt=media&token=e69a2651-70cb-49d5-9b87-6eb8b68250d6",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Communication",
              description: "Communication",
              shortDescription:
                "Communication is the process of exchanging information and ideas with others.",
              lessons: [
                {
                  lessonID: "course 4 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Communication is the process of exchanging information and ideas with others.

        It involves using words, gestures, symbols, or other forms of expression to convey meaning and understanding.
        For example, if you want to ask your professor for an extension on your assignment, you can communicate with them by sending an email, calling them on the phone, or meeting them in person.
        You should use clear and respectful language, explain your situation and reasons, and ask for their feedback.
        By doing this, you are communicating effectively and professionally.
        You are not just sending a text message saying “Hey can I have more time?” That would be rude and unprofessional.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 4 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of communication?",
                        answers: [
                          "The exchange of meanings between individuals through a common system of symbols.",
                          "The process by which messages or information is sent from one place or person to another, or the message itself.",
                          "The process by which information is exchanged between individuals through a common system of symbols, signs, or behavior.",
                          "All of the above.",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "All of the above.",
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 0, 0, 5, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are the basic elements of communication?",
                        answers: [
                          "Sender, receiver, message, channel, feedback, context, and noise.",
                          "Source, destination, signal, medium, response, situation, and interference.",
                          "Speaker, listener, content, mode, reaction, setting, and disturbance.",
                          "Encoder, decoder, data, carrier, acknowledgment, environment, and barrier.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Sender, receiver, message, channel, feedback, context, and noise.",
                        points: 10,
                        explanation:
                          "Sender, receiver, message, channel, feedback, context, and noise.",
                        tagsPoints: [0, 0, 0, 5, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are the different types of communication?",
                        answers: [
                          "Verbal, nonverbal, written, and visual.",
                          "Oral, gestural, textual, and graphical.",
                          "Vocal, bodily, print, and digital.",
                          "Spoken, signalled, inscribed, and displayed.",
                        ],
                        correctAnswer:
                          "Verbal, nonverbal, written, and visual.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "Verbal, nonverbal, written, and visual.",
                        tagsPoints: [0, 0, 0, 5, 0, 0, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 4 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FCommunication%20back.png?alt=media&token=0be4fe3d-a6dc-4fcc-bf19-60cfb8f6f03e&_gl=1*bsunja*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDI1ODUuNjAuMC4w",
                        points: 5,
                        achievement: `
        1) Ask 1 stranger
        2) Ask 3 stragers separately
        3) Actually go on the road they share
                              `,
                        reflection_questions: `
        What was the hardest part when approaching a stranger?

        What strategy helped you to communicate effectively?
                              `,
                        video:
                          "https://youtu.be/xS0lied6pns?si=PJYJFO3QI7uwLXe-",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FCommunication%20back%20(1).png?alt=media&token=3badc8a2-9347-44d6-b056-d36d5ec37473&_gl=1*1qy62hx*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDI2MzUuMTAuMC4w",
                        points: 10,
                        achievement: `
        1) Write down a speech (in bullet points)
        2) Present the speech to a group/friend and get feedback
        3) Film and share the speech/present it in front of the audience
                              `,
                        reflection_questions: `
        Have you been yourself, or have you pretended to be something
        you are not?
        What have you been the most confident about during this Challenge?
                              `,
                        video:
                          "https://youtu.be/tEXFcu1slXY?si=QHrJ0z_ZUTazv9D9",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2F8.png?alt=media&token=ee639fae-ed5e-433b-a63c-feea0e5bb0ef&_gl=1*457t8g*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDI2NjEuNjAuMC4w",
                        points: 15,
                        achievement: `
        1) Research possible topics
        2) Identify the topics you want to tackle
        3) Identify target audience
        4) Set up a vision for the campaign
        5) Set up a SMART goal for the campaign
        6) Identify resources owned/needed
        7) Set up a plan, including a time-frame
        8) Deliver on the plan/campaign
        9) Evaluate successes
                              `,
                        reflection_questions: `
        What was working/wasn’t working in the creation process? How did
        you (as an individual) contribute to it?
        What was the impact of the campaign?
        What were the takeaways from this Challenge for you? How are
        you going to build upon them in the future?
                              `,
                        video:
                          "https://youtu.be/Q4AluEa8BrQ?si=JH_b7o9tk5KES-Ii",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 4 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 3",
            category: "Empathy",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fempathy.png?alt=media&token=c07f01f9-572d-4466-8c2b-5c26fd1563a2",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Empathy",
              description: "Empathy",
              shortDescription:
                "Empathy is the ability to understand and share the feelings of another person.",
              lessons: [
                {
                  lessonID: "course 3 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Empathy is the ability to understand and share the feelings of another person.

        It means putting yourself in their perspective and imagining how they might feel in a certain situation.
        For example, if your classmate is stressed because they have a lot of homework and exams, you can show empathy by saying:
        *“I can see that you are feeling overwhelmed. I know how hard it is to balance school and life. Is there anything I can do to support you?”*

        By doing this, you are being supportive and respectful to your classmate.
        You are not just saying *“Don’t worry, it’s not that bad. You’ll be fine.”* That would be dismissive and unempathetic.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 3 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of empathy?",
                        answers: [
                          "The ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation.",
                          "The feeling of sincere concern for someone who is experiencing something difficult or painful.",
                          "The action of imagining one’s ideas, feelings, or attitudes as fully inhabiting something observed.",
                          "The feeling of loyalty or unity with someone or something.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation.",
                        points: 10,
                        explanation:
                          "The ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation.",
                        tagsPoints: [0, 0, 5, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "How can empathy help to establish relationships and behave compassionately?",
                        answers: [
                          "By sensing, recognizing, and imagining what others are feeling and thinking.",
                          "By enlivening an object or projecting one’s own imagined feelings onto the world.",
                          "By developing representations of possibilities for prosocial actions.",
                          "All of the above.",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "All of the above.",
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 0, 5, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What is the difference between empathy and sympathy?",
                        answers: [
                          "Empathy is a feeling of sincere concern for someone who is experiencing something difficult or painful, while sympathy is the ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation.",
                          "Empathy is the ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation, while sympathy is a feeling of sincere concern for someone who is experiencing something difficult or painful.",
                          "Empathy and sympathy are synonyms and mean the same thing.",
                          "Empathy and sympathy are antonyms and mean the opposite thing.",
                        ],
                        correctAnswer:
                          "Empathy is the ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation, while sympathy is a feeling of sincere concern for someone who is experiencing something difficult or painful.",
                        multipleAnswer: false,
                        points: 10,
                        explanation:
                          "Empathy is the ability to share someone else’s feelings or experiences by imagining what it would be like to be in that person’s situation, while sympathy is a feeling of sincere concern for someone who is experiencing something difficult or painful.",
                        tagsPoints: [0, 0, 5, 0, 0, 0, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 3 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FEmpathy%20back%20(3).png?alt=media&token=c8ade312-527d-4bb7-b2a6-fc91563150c5&_gl=1*19chxcy*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDMzNzQuNDcuMC4w",
                        points: 5,
                        achievement: `
        Every stranger represents an achievement.
        `,
                        reflection_questions: `
        How often do you look others in the eye?
        How do you feel when you do?
        What makes you scared of eye contact?
        `,
                        video:
                          "https://youtu.be/9IahJwAj0qI?si=m_IMc9YkFZlmkevn",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FEmpathy%20back%20(4).png?alt=media&token=3a56a068-c4a1-4188-b4eb-a99cd143cefa&_gl=1*xf1bji*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDM0MjIuNjAuMC4w",
                        points: 10,
                        achievement: `
        Every public transportation stop, or every 10 minutes blindfolded,
        may be considered an achievement.
        `,
                        reflection_questions: `
        How was the experience for a blindfolded person/supporter?
        What surprised you the most?
        What were the best cooperation strategies for you?
        `,
                        video:
                          "https://youtu.be/95VGnIL7Qks?si=WZV2tb59aMUb4JPX",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FEmpathy%20back%20(5).png?alt=media&token=8665546e-e63e-4b77-b271-d9157dad11dc&_gl=1*1dawjhi*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDM0NzEuMTEuMC4w",
                        points: 15,
                        achievement: `
        When the sun rises, you know you achieved it, muhahaha!
        `,
                        reflection_questions: `
        How was the experience for you? What happened?
        What new insights and understandings do you have now, and how
        are you going to use them?
        `,
                        video:
                          "https://youtu.be/lTudvkqNOsA?si=WbpCh034v8IxLaYg",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 3 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 1",
            category: "Problem Solving",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fproblem%20solving.png?alt=media&token=86b2835d-170b-4cc7-8963-e0c875426e55",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Problem Solving",
              description: "Problem Solving",
              shortDescription:
                "Problem solving is the process of finding solutions to difficult or complex issues.",
              lessons: [
                {
                  lessonID: "course 1 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Problem solving is the process of finding solutions to difficult or complex issues.

        It involves identifying the problem, analysing it, generating possible solutions, evaluating them, and choosing the best one.
        For example, if you have a group project due tomorrow and your partner has not done their part, you can use problem solving skills to deal with the situation.
        You can identify the problem as a lack of communication and cooperation from your partner.
        You can analyse it by asking yourself why your partner has not done their part, what are the consequences of not finishing the project, and what are your options.
        You can generate possible solutions such as talking to your partner, asking for an extension, doing their part yourself, or reporting them to the teacher.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 1 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of problem solving?",
                        answers: [
                          "The process of a problem analysis and resolving it in the best way possible for that situation.",
                          "The act of finding a solution to a problem without data or evidence.",
                          "The feeling of satisfaction or frustration after dealing with a problem.",
                          "The skill of avoiding or ignoring problems that are too difficult or complex.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The process of a problem analysis and resolving it in the best way possible for that situation.",
                        points: 10,
                        explanation:
                          "The process of a problem analysis and resolving it in the best way possible for that situation.",
                        tagsPoints: [5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are the basic four steps of the problem-solving process?",
                        answers: [
                          "Define the problem, generate alternative solutions, evaluate and select an alternative, implement and follow up on the solution.",
                          "Identify the problem, brainstorm possible solutions, test and refine solutions, choose and implement the best solution.",
                          "Analyze the problem, design a solution, develop and test the solution, deploy and monitor the solution.",
                          "Understand the problem, plan a strategy, carry out the plan, review and extend the results.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Define the problem, generate alternative solutions, evaluate and select an alternative, implement and follow up on the solution.",
                        points: 10,
                        explanation:
                          "Define the problem, generate alternative solutions, evaluate and select an alternative, implement and follow up on the solution.",
                        tagsPoints: [5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What is the difference between problem solving and decision making?",
                        answers: [
                          "Problem solving is the process of finding a solution to a problem, while decision making is the process of choosing among alternatives.",
                          "Problem solving and decision making are synonyms and mean the same thing.",
                          "Problem solving is the process of identifying a problem, while decision making is the process of implementing a solution.",
                          "Problem solving and decision making are antonyms and mean the opposite thing.",
                        ],
                        correctAnswer:
                          "Problem solving is the process of finding a solution to a problem, while decision making is the process of choosing among alternatives.",
                        multipleAnswer: false,
                        points: 10,
                        explanation:
                          "Problem solving is the process of finding a solution to a problem, while decision making is the process of choosing among alternatives.",
                        tagsPoints: [5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 1 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FProblem%20Solving%20back.png?alt=media&token=f64c962e-71a2-4b83-b40e-203b4983e5eb&_gl=1*1go6xhd*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDI5NjQuNDUuMC4w",
                        points: 5,
                        achievement: `
        1) Identify a game or games you want to play
        2) Identify a place
        3) Identify promotion strategy
        4) Play the energizers
        5) Evaluate your successes
        `,
                        reflection_questions: `
        How did you feel before and after the energizer?
        How did you attract people to your energizer?
        What were your successes within the Challenge?
                              `,
                        video:
                          "https://youtu.be/zD-raA34TEY?si=4ci0dzvjbBolVOmG",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FProblem%20solving%20back.png?alt=media&token=b5e60217-30c1-4e5a-ae71-a78fa7696d2e&_gl=1*1bo9i0e*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDMwMjMuNTQuMC4w",
                        points: 10,
                        achievement: `
        1) Identify topics you would like to learn more about
        2) Identify possible places to visit
        3) Contact the site(s)
        4) Visit the site(s)
                              `,
                        reflection_questions: `
        How were the companies reacting? What helped you to succeed?
        What did you learn during the visit?
                   `,
                        video:
                          "https://youtu.be/0_5UOAg_1vI?si=E-Llp4tfmrJyKUGT",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FProblem%20solving%20back%20(1).png?alt=media&token=70261513-7b6a-44ce-b26d-e2a22de19e23&_gl=1*n6crz*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDMwODAuNjAuMC4w",
                        points: 15,
                        achievement: `
        1) Research what Guinness world records there are
        2) Choose the one you are going to break
        3) Make a plan
        4) Implement it
                             `,
                        reflection_questions: `
        Did you break the record? How does it feel to be the best in the
        world at something?
        What helped you to get as far as you did?
        Would you like to do something similar in the future, and if so,
        what?
        `,
                        video:
                          "https://youtu.be/PJi3LuVfRts?si=WOUCATRF5Nko3Ok5",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 1 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 5",
            category: "Active Listening",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Factive%20listening.png?alt=media&token=e75198e9-50e9-457a-a0f8-ab827970795b",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Active Listening",
              description: "Active Listening",
              shortDescription:
                "Active listening skills are the ability to pay attention and show interest in what someone else is saying.",
              lessons: [
                {
                  lessonID: "course 5 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Active listening skills are the ability to pay attention and show interest in what someone else is saying.

        It means being able to listen carefully, ask questions, paraphrase, and give feedback.

        For example, if your friend is telling you about their day, you can use active listening skills by nodding, saying “uh-huh” or “I see”, asking them to elaborate, summarising what they said, and expressing your opinion or empathy.

        By doing this, you are being an active listener and a good friend.

        You are not just looking at your phone, interrupting them, changing the subject, or judging them. That would be passive or rude.

        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 5 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question:
                          "What is the difference between hearing and listening?",
                        answers: [
                          "Hearing is a passive process, while listening is an active process.",
                          "Hearing is an active process, while listening is a passive process.",
                          "Hearing and listening are the same thing.",
                          "Hearing is a physical process, while listening is a mental process.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Hearing is a passive process, while listening is an active process.",
                        points: 10,
                        explanation:
                          "Hearing is a passive process, while listening is an active process.",
                        tagsPoints: [0, 0, 0, 0, 5, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are some non-verbal cues that can indicate active listening?",
                        answers: [
                          "Smiling, nodding, leaning in, and making eye contact.",
                          "Folding arms, looking away, yawning, and frowning.",
                          "Interrupting, giving advice, judging, and criticizing.",
                          "Repeating, summarizing, paraphrasing, and reflecting.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Smiling, nodding, leaning in, and making eye contact.",
                        points: 10,
                        explanation:
                          "Smiling, nodding, leaning in, and making eye contact.",
                        tagsPoints: [0, 0, 0, 0, 5, 0, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are some benefits of active listening in the workplace?",
                        answers: [
                          "It can lead to clearer communication and more effective relationships.",
                          "It can help you manage your emotions and retain information better.",
                          "It can help you resolve conflicts and demonstrate empathy.",
                          "All of the above.",
                        ],
                        correctAnswer: "All of the above.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 0, 0, 0, 5, 0, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 5 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FActive%20Listening%20back%20(2).png?alt=media&token=54711d08-62e0-4d06-83ed-2c85525af114&_gl=1*128du0*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDQ1NDUuMzMuMC4w",
                        points: 5,
                        achievement: `
        1) Don't say anything during the listening.
        2) Thank the person for the sharing
        `,
                        reflection_questions: `
        Are you truly listening to others, or are you just waiting for your
        chance to talk?
        How was it for you to be completely silent?
        What helped you to stay focused on your game partner?
        How can this be useful for you in everyday life?
        In what life situations do you think this competence is needed the
        most?
        `,
                        video:
                          "https://youtu.be/VidfD31itMo?si=4GncSM6-XOMoV7At",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FActive%20Listening%20back%20(3).png?alt=media&token=46c41d8d-27e3-493e-9e07-91e5344e36bc&_gl=1*es2hs2*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDQ1NjQuMTQuMC4w",
                        points: 10,
                        achievement: `
        1) Manage to find a person over 50
        2) Introduce yourself and your curiosity
         `,
                        reflection_questions: `
        Who did you talk to and what is your biggest takeaway from it?
        `,
                        video:
                          "https://youtu.be/mJBeAXxyv7M?si=g2JrzSMvtoLFmPAU",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FActive%20listening%20back%20(1).png?alt=media&token=bea9a1c0-70a4-4dae-83ea-1b632e5d106f&_gl=1*1t99e*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDQ1NzMuNS4wLjA.",
                        points: 15,
                        achievement: `
        1) Define what a “meaningful connection” means to you
        2) Find a person you have not met before
        3) Establish a connection
        4) Establish your “meaningful connection”
        5) Share your secret with them
        6) Ask them to share a secret with you
        `,
                        reflection_questions: `
        What does a “meaningful connection” mean to you?
        Did you establish it with somebody? What helped you to establish
        it?
        Are you going to see that person again?
        With how many of your friends do you have a “meaningful connection”?
        Can you do something to make the connections you already
        have more meaningful?
        `,
                        video:
                          "https://www.youtube.com/watch?v=PJi3LuVfRts&list=PLfBDsTUntWXeJ-dmv15Al6t5reiytJaNb&index=5",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 1 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 6",
            category: "Organisation Skills",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Forganisation%20skills.png?alt=media&token=28bda06d-9f38-4e0f-8829-a5a18b4de92f",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Organisation Skills",
              description: "Organisation Skills",
              shortDescription:
                "Organisational skills are the ability to plan, prioritise, and manage your time and resources effectively.",
              lessons: [
                {
                  lessonID: "course 6 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Organisational skills are the ability to plan, prioritise, and manage your time and resources effectively.

        It means being able to set goals, create schedules, follow deadlines, and complete tasks efficiently.

        For example, if you have a group project due next week, you can use organisational skills by dividing the work among your team members, assigning roles and responsibilities, communicating regularly, and checking the progress and quality of the work.

        By doing this, you are being organised and responsible.

        You are not just leaving everything to the last minute or expecting others to do everything for you. That would be chaotic and unreliable.

        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 5 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question:
                          "What is the definition of organizational skills?",
                        answers: [
                          "The skills that allow you to use your time, energy, resources, etc. in an effective way so that you achieve the things you want to achieve.",
                          "The skills that involve finding and arranging information in a logical and coherent way.",
                          "The skills that involve creating and following a set of rules or procedures for a specific task or situation.",
                          "The skills that involve coordinating and managing people, projects, and resources.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The skills that allow you to use your time, energy, resources, etc. in an effective way so that you achieve the things you want to achieve.",
                        points: 10,
                        explanation:
                          "The skills that allow you to use your time, energy, resources, etc. in an effective way so that you achieve the things you want to achieve.",
                        tagsPoints: [0, 0, 0, 0, 0, 5, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "What are some examples of organizational skills?",
                        answers: [
                          "Time management, communication, setting goals, and delegation.",
                          "Creativity, innovation, critical thinking, and collaboration.",
                          "Leadership, motivation, negotiation, and persuasion.",
                          "Flexibility, adaptability, resilience, and initiative.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Time management, communication, setting goals, and delegation.",
                        points: 10,
                        explanation:
                          "Time management, communication, setting goals, and delegation.",
                        tagsPoints: [0, 0, 0, 0, 0, 5, 0, 0, 0, 0],
                      },
                      {
                        question:
                          "How can you improve your organizational skills?",
                        answers: [
                          "By using tools such as calendars, planners, lists, and apps to keep track of your tasks and deadlines.",
                          "By asking for help from others when you are overwhelmed or unsure of what to do next.",
                          "By taking breaks and relaxing when you feel stressed or tired.",
                          "By learning new skills and acquiring new knowledge that can help you perform better.",
                        ],
                        correctAnswer:
                          "By using tools such as calendars, planners, lists, and apps to keep track of your tasks and deadlines.",
                        multipleAnswer: false,
                        points: 10,
                        explanation:
                          "By using tools such as calendars, planners, lists, and apps to keep track of your tasks and deadlines.",
                        tagsPoints: [0, 0, 0, 0, 0, 5, 0, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 6 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FOrganisational%20Skills%20back.png?alt=media&token=ef03440b-8430-4efc-a2e5-f5730229ecd7&_gl=1*1evplo8*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDUxNzcuNDYuMC4w",
                        points: 5,
                        achievement: `
        1) Find a location for your event
        2) Define the type of event you want to organise
        3) Define roles and responsibilities in the team
        4) Make agreements with the location you’ve chosen
        5) Promote the event
        6) Run the event
        7) Evaluate the event
        `,
                        reflection_questions: `
        Did you have fun in the process, and if yes/no, how come?
        What was working/not working during the process?
        What was your best experience during the process?
        `,
                        video:
                          "https://youtu.be/BKee0CYew7A?si=aSI1j5pfCdZsSjjs",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FOrganisational%20Skills%20back%20(1).png?alt=media&token=a4f922f0-ace4-4f67-a478-234932ece28a&_gl=1*1g2xpgi*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDUxODkuMzQuMC4w",
                        points: 10,
                        achievement: `
        1) Identify what are you going to do
        2) Make a plan, including a timeline, and a way to promote your
        event
        3) Implement the plan
        4) Evaluate your successes
         `,
                        reflection_questions: `
        What was the hardest part of the organising process for you?
        What helped you to stay organised?
        What helped you to coordinate with others?
        `,
                        video:
                          "https://youtu.be/Wf5k-qa4ulM?si=9e0bX70wC4nghT-O",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FOrganisational%20skills%20back.png?alt=media&token=901d5ce0-6c36-4bab-ae24-e99c1ee8b304&_gl=1*o6l75w*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzc0MjM3MS4xMy4xLjE2OTc3NDUxOTYuMjcuMC4w",
                        points: 15,
                        achievement: `
        1) Identify area and target audience
        2) Identify the target audience’s Challenges and needs
        3) Identify personal values the players in the team are bringing
        into the process
        4) Identify shared values
        5) Align the group with a shared vision
        6) Set up a SMART goal of what you want to achieve
        7) Identify the resources owned/needed
        8) Come up with a plan, including a time frame
        9) Execute the plan
        10) Implement the volunteering action
        11) Evaluate your successes
        `,
                        reflection_questions: `
        What was working/wasn’t working in your cooperation? How did
        you (as an individual) contribute to it?
        How did the volunteering action go? What was the reaction of the
        target audience?
        What were the main takeaways for you? How are you going to
        build upon them in the future?
        `,
                        video:
                          "https://youtu.be/cu-X0DRlEL8?si=DdWDp1gN_UHGcq41",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 6 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 7",
            category: "Resilience",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2FRESILIENCE.png?alt=media&token=8e53529d-73bc-4176-afe6-177a3d312217",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Resilience",
              description: "Resilience",
              shortDescription:
                "Resilience is the ability to cope with and overcome challenges, difficulties, or setbacks.",
              lessons: [
                {
                  lessonID: "course 7 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Resilience is the ability to cope with and overcome challenges, difficulties, or setbacks.

        It means being able to bounce back, learn from your experiences, and adapt to changing circumstances.

        For example, if you fail an exam, you can use resilience by accepting your mistake, seeking feedback, studying harder, and trying again.

        By doing this, you are being resilient and optimistic.

        You are not just giving up, blaming yourself or others, avoiding the subject, or feeling hopeless. That would be fragile and pessimistic.

        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 7 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of resilience?",
                        answers: [
                          "The ability to cope with and recover from setbacks.",
                          "The ability to avoid stress, adversity or trauma.",
                          "The ability to bounce back to your original shape after deformation.",
                          "The ability to resist change and maintain stability.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The ability to cope with and recover from setbacks.",
                        points: 10,
                        explanation:
                          "The ability to cope with and recover from setbacks.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 5, 0, 0, 0],
                      },
                      {
                        question:
                          "What is one characteristic of resilient people?",
                        answers: [
                          "They view themselves as survivors, not victims.",
                          "They avoid negative emotions and focus on the positive.",
                          "They rely on themselves and don’t ask for help.",
                          "They resist change and stick to their routines.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "They view themselves as survivors, not victims.",
                        points: 10,
                        explanation:
                          "They view themselves as survivors, not victims.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 5, 0, 0, 0],
                      },
                      {
                        question:
                          "What is one strategy to become more resilient?",
                        answers: [
                          "Cultivate optimism and gratitude.",
                          "Seek social support and ask for help when needed.",
                          "Find meaning and purpose in what you do.",
                          "Any of the above.",
                        ],
                        correctAnswer: "Any of the above.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "Any of the above.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 5, 0, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 7 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FResilience%20back.png?alt=media&token=6ccb5c44-3c38-4dac-a06d-69c7637d139d&_gl=1*1w8u72v*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQyNzMuNjAuMC4w",
                        points: 5,
                        achievement: `
        Each person researched is an achievement. The final presentation is another achievement.
        `,
                        reflection_questions: `
        What does failure mean to you?
        What was your biggest fail?
        What was the most inspiring thing you found out that you would
        like to practise as well? What is your action plan for practising it?
        `,
                        video:
                          "https://youtu.be/B9addTUNLEY?si=ulcuENMX4ibe_9NU",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FResilience%20back%20(1).png?alt=media&token=c51a6a22-ef36-4079-88de-d3bf63ccee4d&_gl=1*s6grn7*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQzNDAuNTguMC4w",
                        points: 10,
                        achievement: `
        Every activity accomplished with a non-dominant hand is an achievement.
         `,
                        reflection_questions: `
        How is it to live the other way around?
        What did this experience bring to you?
        `,
                        video:
                          "https://youtu.be/Hpr2hS6nl6s?si=0iVReyPvSZNahY_j",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FResilience%20back%20(2).png?alt=media&token=784ebc4a-35d3-407d-97af-8136593ce039&_gl=1*bhiszt*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQzODQuMTQuMC4w",
                        points: 15,
                        achievement: `
        1) Gain an understanding of what a citizens’ initiative is and how
        it works
        2) Identify the area and target audience
        3) Identify the target audiences’ Challenges and needs
        4) Identify personal values the players in the team are bringing
        into the process
        5) Identify shared values
        6) Align the group with a shared vision
        7) Set up a SMART goal of what you want to achieve
        8) Identify resources owned/needed
        9) Come up with a plan, including a time frame
        10) Create the citizenship initiative
        11) Gain 5 supporters
        12) Evaluate your successes
        13) Gain 10 supporters
        14) Evaluate your successes
        15) Gain 20 supporters
        16) Evaluate your successes
        17) Gain 25 supporters
        18) Evaluate your successes
        19) Decide on how to proceed
        `,
                        reflection_questions: `
        How can you inspire people to join your initiative?
        How are you coping with experiencing rejection?
        What is holding you back from success?
        `,
                        video:
                          "https://youtu.be/nDJry1B7ipM?si=Y5p9TkIET5-PRLmL",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 7 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 8",
            category: "Digital Skills",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fdigital%20skills.png?alt=media&token=3576c797-0d5f-40e0-9efa-566b3c9d4943",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Digital Skills",
              description: "Digital Skills",
              shortDescription:
                "Digital skills are the ability to use technology effectively and responsibly.",
              lessons: [
                {
                  lessonID: "course 8 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Digital skills are the ability to use technology effectively and responsibly.

        It means being able to access, create, share, and evaluate digital information, products, or services.

        For example, if you want to make a presentation, you can use digital skills by choosing appropriate software, designing a clear and attractive layout, adding relevant content and multimedia, and citing your sources.

        By doing this, you are being digitally skilled and literate.

        You are not just using random or outdated software, making a messy or boring presentation, copying or plagiarising content, or ignoring your sources.

        That would be digitally unskilled or illiterate.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 8 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What are digital skills?",
                        answers: [
                          "The skills needed to use digital devices, communication applications, and networks to access and manage information.",
                          "The skills needed to create digital content, such as websites, apps, and games.",
                          "The skills needed to analyze and interpret data using digital tools, such as spreadsheets, databases, and visualization software.",
                          "The skills needed to protect and secure digital information, such as passwords, encryption, and backups.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The skills needed to use digital devices, communication applications, and networks to access and manage information.",
                        points: 10,
                        explanation:
                          "The skills needed to use digital devices, communication applications, and networks to access and manage information.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 5, 0, 0],
                      },
                      {
                        question:
                          "Which of these is an example of an advanced digital skill?",
                        answers: [
                          "Sending an email with an attachment.",
                          "Searching for information on the internet.",
                          "Developing a web application using HTML, CSS, and JavaScript.",
                          "Using a word processor to write a document.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "Developing a web application using HTML, CSS, and JavaScript.",
                        points: 10,
                        explanation:
                          "Developing a web application using HTML, CSS, and JavaScript.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 5, 0, 0],
                      },
                      {
                        question:
                          "Why are digital skills important for the future of work?",
                        answers: [
                          "Because digital technologies are changing how work is done and what skills are needed in different industries and occupations.",
                          "Because digital skills are required for most jobs and can increase employability and career opportunities.",
                          "Because digital skills can help workers communicate and collaborate effectively, develop and share digital content, and problem solve in a work-anywhere world.",
                          "All of the above.",
                        ],
                        correctAnswer: "All of the above.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 5, 0, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 8 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FDigital%20Skills%20back.png?alt=media&token=3ed5e8d7-bfcc-4571-89a0-7d380b2ed8e2&_gl=1*kmjbrx*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQ3ODguNjAuMC4w",
                        points: 5,
                        achievement: `
        Contact with a public figure is already an achievement, recording a video is another one.
        `,
                        reflection_questions: `
        How was it for you?
        What helped you to succeed?
        `,
                        video:
                          "https://youtu.be/GY2B4__QAa4?si=7UeP_CnY5HEoAZow",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FDigital%20Skills%20back%20(1).png?alt=media&token=3badbd3b-b29f-4b1b-8ebd-03b124c2dfc8&_gl=1*1dd5ua0*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQ4MzQuMTQuMC4w",
                        points: 10,
                        achievement: `
        Every new page is a form of achievement.
        `,
                        reflection_questions: `
        What was the most interesting information you learned along the
        way?
        Which of your assumptions proved to be right, and which proved to
        be wrong?
        `,
                        video:
                          "https://youtu.be/IeCyZnzf5xY?si=332RpIVm2wa3cci0",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FDigital%20Skills%20back%20(2).png?alt=media&token=fedd2cb5-ba4e-4ccc-aac8-3501e5d2f7d9&_gl=1*1j9a1at*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTQ5MDAuNjAuMC4w",
                        points: 15,
                        achievement: `
        1) Choose a topic for the call
        2) Set up the date of the call
        3) Promote the event and gather participants
        4) Do the call
        5) Evaluate the call
        `,
                        reflection_questions: `
        How did you gather participants? What were the most successful
        strategies?
        How did the call go? What would you do differently next time and
        what would you do again?
        `,
                        video:
                          "https://youtu.be/Fcgp-RAqJ5o?si=BUCCySS0Z_xsxsHx",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 8 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 9",
            category: "Teamwork",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fteamwork.png?alt=media&token=7eda01eb-4c27-4611-9f5c-ccca54b9136a",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Teamwork",
              description: "Teamwork",
              shortDescription:
                "Teamwork is the ability to work well with others towards a common goal.",
              lessons: [
                {
                  lessonID: "course 9 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Teamwork is the ability to work well with others towards a common goal.

        It means being able to cooperate, collaborate, communicate, and compromise with your team members.

        For example, if you are playing a soccer game, you can use teamwork by passing the ball, supporting your teammates, listening to your coach, and following the rules.

        By doing this, you are being a good team player and a leader.

        You are not just hogging the ball, criticising your teammates, ignoring your coach, or cheating. That would be selfish and disrespectful.

        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 9 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of teamwork?",
                        answers: [
                          "The activity or quality of working together in a group with a common purpose or goal.",
                          "The skill or ability of working well with other people in a friendly and cooperative way.",
                          "The process or method of dividing work among a group of people and coordinating their efforts.",
                          "The result or outcome of a group of people working together to achieve a desired objective.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "The activity or quality of working together in a group with a common purpose or goal.",
                        points: 10,
                        explanation:
                          "The activity or quality of working together in a group with a common purpose or goal.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 5, 0],
                      },
                      {
                        question: "What are some benefits of teamwork?",
                        answers: [
                          "It can increase productivity, efficiency, and quality of work.",
                          "It can enhance creativity, learning, and problem-solving skills.",
                          "It can improve communication, trust, and morale among team members.",
                          "It can reduce stress, conflict, and turnover rates.",
                        ],
                        multipleAnswer: false,
                        correctAnswer:
                          "It can improve communication, trust, and morale among team members.",
                        points: 10,
                        explanation:
                          "It can improve communication, trust, and morale among team members.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 5, 0],
                      },
                      {
                        question: "How can you improve your teamwork skills?",
                        answers: [
                          "By being respectful, supportive, and reliable to your team members.",
                          "By being creative, innovative, and independent in your work.",
                          "By being confident, assertive, and persuasive in your communication.",
                          "By being flexible, adaptable, and resilient in challenging situations.",
                        ],
                        correctAnswer:
                          "By being respectful, supportive, and reliable to your team members.",
                        multipleAnswer: false,
                        points: 10,
                        explanation:
                          "By being respectful, supportive, and reliable to your team members.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 5, 0],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 9 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FTeamwork%20back.png?alt=media&token=5bbf68ef-c6e2-466f-8516-029229d2c478&_gl=1*1thxq4l*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTU0NjguNjAuMC4w",
                        points: 5,
                        achievement: `
        Contact with a public figure is already an achievement, recording a video is another one.
        `,
                        reflection_questions: `
                                How did you feel during the Challenge?
        How does physical touch influence your relationship with others?
        Did you see the Challenge as an obstacle, or as an opportunity?
        How come?
        `,
                        video:
                          "https://youtu.be/HXMHyaoGVlc?si=dQ542VgrO3sPZdm1",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FTeamwork%20back%20(1).png?alt=media&token=b1db927e-5380-4c63-a46e-c8939ec443fb&_gl=1*1lb060m*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTU1MzAuNjAuMC4w",
                        points: 10,
                        achievement: `
        1) Form a band
        2) Choose a song
        3) Practise the performance
        4) Perform
        5) Celebrate the performance
        `,
                        reflection_questions: `
        How was it to be seen?
        What is preventing you from going all-in in life?
        `,
                        video:
                          "https://youtu.be/OKKUX5eEJEc?si=2oEWfafPctZlYxu6",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FTeamwork%20back%20(2).png?alt=media&token=95593ff6-e975-4428-b393-bb7828f9c137&_gl=1*1abj0ch*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTU1MzcuNTMuMC4w",
                        points: 15,
                        achievement: `
        1) Form the group
        2) Agree on a social issue meaningful to you
        3) Set up a clear vision, goal, objectives, and plan
        4) Identify resources needed/owned
        5) Implement a street event
        6) Evaluate the street event
        `,
                        reflection_questions: `
        How was it to be seen?
        What is preventing you from going all-in in life?
        `,
                        video:
                          "https://youtu.be/I7HBlF3vxB0?si=K-0IjId1rlj1dtaP",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 9 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        },
        {
          meta: {
            courseID: "course 10",
            category: "Creativity",
            previewImage:
              "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2Fcourses%2Fimg-courses%2Fcreativity.png?alt=media&token=b3bba5c3-a77e-48bb-9d10-fafc83578d58",
            creationTimestamp: "April 24, 2023 at 10:06:51 PM UTC+3",
            creatorID: "none",
          },
          withLanguages: [
            {
              language: "en",
              name: "Creativity",
              description: "Creativity",
              shortDescription:
                "Creativity is the ability to generate new and original ideas, solutions, or products.",
              lessons: [
                {
                  lessonID: "course 10 lesson 1",
                  lessonType: "Text",
                  title: "Course Description",
                  description: `
        Creativity is the ability to generate new and original ideas, solutions, or products.

        It means being able to think outside the box, use your imagination, and experiment with different possibilities.

        For example, if you want to write a short story, you can use creativity by choosing an interesting topic, developing a plot and characters, using descriptive language and dialogue, and adding a twist or surprise at the end.

        By doing this, you are being creative and expressive.

        You are not just copying someone else’s story or writing something boring and predictable. That would be unoriginal and dull.
        `,
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 10 lesson 2",
                  lessonType: "Quiz",
                  title: "Quiz",
                  description: "",
                  resource: {
                    source: "",
                    title: "",
                    quiz: [
                      {
                        question: "What is the definition of creativity?",
                        answers: [
                          "The ability to produce or use original and unusual ideas²⁴.",
                          "The phenomenon whereby something new and valuable is formed⁵.",
                          "The process by which messages or information is sent from one place or person to another³.",
                          "Both A and B.",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "Both A and B.",
                        points: 10,
                        explanation: "Both A and B.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
                      },
                      {
                        question: "What are some examples of creativity?",
                        answers: [
                          "Solving a problem, communicating with others, and entertaining⁴.",
                          "Designing a product, writing a story, and painting a picture⁵.",
                          "Setting a goal, managing time, and delegating tasks³.",
                          "Both A and B.",
                        ],
                        multipleAnswer: false,
                        correctAnswer: "Both A and B.",
                        points: 10,
                        explanation: "Both A and B.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
                      },
                      {
                        question: "How can you improve your creativity?",
                        answers: [
                          "By using tools such as brainstorming, mind mapping, and SCAMPER to generate and organize ideas¹.",
                          "By asking for help from others when you are stuck or need feedback³.",
                          "By taking breaks and relaxing when you feel bored or frustrated¹.",
                          "All of the above.",
                        ],
                        correctAnswer: "All of the above.",
                        multipleAnswer: false,
                        points: 10,
                        explanation: "All of the above.",
                        tagsPoints: [0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
                      },
                    ],
                  },

                  points: 0,
                },
                {
                  lessonID: "course 10 lesson 3",
                  lessonType: "QuizImg",
                  title: "Challenge yourself!",
                  description: "",
                  resource: {
                    challenge: [
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FCreativity%20back.png?alt=media&token=07d0ea20-36e6-44a8-9465-67b488c799d0&_gl=1*1wd0kk8*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTYzMTguNjAuMC4w",
                        points: 5,
                        achievement: `
        1) Brainstorm an art piece idea
        2) Research local laws, so you don’t end up in prison, and get the
        necessary permissions for your artwork
        3) Create the artwork
        `,
                        reflection_questions: `
        What did this experience bring to you?
        Would you like to do this again?
        `,
                        video:
                          "https://youtu.be/taJjXPmL1qY?si=ejjY0KMMucoXYBc8",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FCreativity%20back%20(1).png?alt=media&token=4678d3ab-c58a-4d85-8767-0c498e5d432d&_gl=1*fju3w2*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTYzNDYuMzIuMC4w",
                        points: 10,
                        achievement: `
        1) Craft an instrument
        2) Practice songs
        3) Select a spot
        4) Perform the street concert
        `,
                        reflection_questions: `
        How much did you think outside of the box?
        How was it for you, did you have fun? How come?
        `,
                        video:
                          "https://www.youtube.com/watch?v=9OnG7ItEe3I&list=PLfBDsTUntWXcyJHH6mhMsFpC9Xshl76Fk&index=12",
                      },
                      {
                        img: "https://firebasestorage.googleapis.com/v0/b/employed-2da93.appspot.com/o/images%2FCreativity%20back%20(2).png?alt=media&token=24fab856-d8d0-489a-a475-5028cef1c12d&_gl=1*1q6deso*_ga*MTEwNzk1ODAwMi4xNjkzMzA2NjIw*_ga_CW55HF8NVT*MTY5Nzk5MzkwNS4xNC4xLjE2OTc5OTYzNzQuNC4wLjA.",
                        points: 15,
                        achievement: `
        1) Discover your resources - materials to be upcycled/recycled
        2) Make a design
        3) Create a prototype and test it
        4) Adapt the prototype
        5) Wear the result
        `,
                        reflection_questions: `
        What can you do with materials you have around and would throw
        away?
        How can you limit the amount of waste you produce?
        `,
                        video:
                          "https://youtu.be/9OnG7ItEe3I?si=LnrNiB7j_kQVQj_2",
                      },
                    ],
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
                {
                  lessonID: "course 10 lesson 5",
                  lessonType: "Text",
                  title: "Completion",
                  description: "Thank you for completing our course!",
                  resource: {
                    source: "false",
                    title: "",
                  },
                  points: 0,
                },
              ],
            },
          ],
        }
      );

      for (let course of courses) {
        course.withLanguages[0].shortDescription =
          course.withLanguages[0].lessons[0].description.split(".")[0] + ".";
      }

      setCourses(courses);
      if (!coursesReady) {
        setTimeout(() => {
          setCoursesReady(true);
        }, 10);
      }
    };

    get_courses().catch(console.error);

    getAllUsers().catch(console.error);

    if (!reviewRequestsReady) {
      getReviewRequests().catch(console.error);
    }

    if (!reviewRequestsReady) {
      getReviewResponses().catch(console.error);
    }

    return () => {
      unsubscribe();
    };
  }, []);

  if (
    userReady &&
    user !== null &&
    user !== undefined &&
    Object.keys(user).length !== 0 &&
    coursesReady &&
    !userCoursesReady
  ) {
    let uCourses = user.getAttendedCourses(courses);

    setUserCourses(uCourses);
    setUsercoursesReady(true);
  }

  if (
    userReady &&
    user !== null &&
    user !== undefined &&
    Object.keys(user).length !== 0 &&
    coursesReady &&
    !userCoursesReady &&
    !userLessonsCourseReady
  ) {
    let uCourses = user.getFinishedLessons(courses);

    setUserLessonsCourse(uCourses);
    setUserLessonsCourseReady(true);
  }

  let content = props.children;
  if (
    !userReady ||
    !coursesReady ||
    !reviewRequestsReady ||
    !reviewResponsesReady
  ) {
    content = <Loading />;
  }

  if (userReady) {
    if (user.preferedTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }

  return (
    <AuthContext.Provider
      value={{
        googleSignIn,
        facebookSignIn,
        twitterSignIn,
        githubSignIn,
        signIn,
        createUser,
        logOut,
        user,
        courses,
        userCourses,
        reviewRequests,
        reviewResponses,
        allUsers,
        uploadFile,
        getFileUrl,
        updateUser,
        setUserCourses,
        userSendEmailVerification,
        userSendPasswordReset,
        userLessonsCourse,
        setUserLessonsCourse,
        submitReviewRequest,
        submitReviewResponse,
      }}
    >
      {content}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
