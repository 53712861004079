import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactPlayer from "react-player";
import "../../quiz.css";
interface LessonVideo {
  description: string;
  lessontitle: string;
  video: string;
}

function LessonVideo(props: LessonVideo) {
  return (
    <div className="cardquiz">
      <ReactPlayer width="100%" height="100%" url={props.video} controls />
    </div>
  );
}

export default LessonVideo;
