import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import RadarComponent from "../components/RadarComponent";

import { Link } from "react-router-dom";
import { Course } from "../utils/CourseClass";
import "../index.css";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  const { user, reviewResponses } = UserAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  let { userCourses } = UserAuth();

  let { courses } = UserAuth();
  let number: any = 0;

  if (!userCourses.length) {
    number = 0;
  }

  let reviews_count = 0;

  for (const [reqId, req] of reviewResponses) {
    if (user.authObject.uid == req.to) {
      reviews_count += 1;
    }
  }

  for (let i = 0; i < courses.length; i++) {
    user.isAttendingCourse(courses[i].meta.courseID);
  }
  function generateRandomLinearGradient() {
    const colors = [
      "#9CC0AC",
      "#82bda9",
      "#83accf",
      "#90bad2",
      "#bdb0b6",
      "#cf9380",
      "#83bda9",
    ];
    const color1 = colors[getRandomInt(0, colors.length - 1)];
    const color2 = colors[getRandomInt(0, colors.length - 1)];

    return `linear-gradient(232deg, ${color1} 0%, ${color2} 100%)`;
  }

  function addRandomVariation(color: any) {
    const variation = 20; // You can adjust this value for smaller or larger variation
    const r = getRandomInt(0, 255);
    const g = getRandomInt(0, 255);
    const b = getRandomInt(0, 255);

    return `rgba(${clamp(r)}, ${clamp(g)}, ${clamp(b)}, 0.85)`;
  }

  function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function clamp(value: any, min = 0, max = 255) {
    return Math.min(max, Math.max(min, value));
  }

  const filteredCourses = courses.filter((courses: any) => {
    // Check some condition here, e.g., course meets certain criteria
    // and return true if it should be included in the filtered array
    for (let i = 0; i < courses.withLanguages.length; i++) {
      for (let j = 0; j < courses.withLanguages[i].lessons.length; j++) {
        return courses.withLanguages[i].lessons[j].lessonType !== "Audio";
      }
    }
    // Change this condition as needed
  });
  useEffect(() => {
    if (user === null || user === undefined || Object.keys(user).length === 0) {
      return;
    }
    user.saveDBUser();
  }, []);

  return (
    <>
      <div className="overlap-2  ">
        <div className="flex">
          <div className="overlap-3">
            {" "}
            <div className="frame-2">
              {filteredCourses.map((course: Course) => (
                <Link to={"/dashboard/courses/en/" + course.meta.courseID}>
                  <div key={course.meta.courseID} className="card-wrapper">
                    {" "}
                    <div
                      className="cardhome"
                      style={{ background: generateRandomLinearGradient() }}
                    >
                      <div className="overlap-group-2">
                        <div className="frame-3">
                          <div className="text-wrapper-5">
                            {" "}
                            <h5>{course.withLanguages[0].name}</h5>
                          </div>
                        </div>
                        <img
                          className="rectangle-2home"
                          alt="Rectangle"
                          src={course.meta.previewImage}
                        />
                      </div>
                    </div>{" "}
                  </div>{" "}
                </Link>
              ))}
            </div>{" "}
            <div className="profile-card">
              <div className="overlap-4">
                <div className="rectangle-10" />
                <div className="rectangle-11" />
                <div className="frame-4">
                  <div className="text-wrapper-6">
                    {user.authObject.displayName}
                  </div>
                  <div className="text-wrapper-7">{user.authObject.email}</div>
                </div>

                <img
                  className="check"
                  alt="Check"
                  src={
                    user.authObject.emailVerified
                      ? "https://c.animaapp.com/3fc6Cqeo/img/check@2x.png"
                      : "https://cdn-icons-png.flaticon.com/512/169/169779.png"
                  }
                />

                <div className="frame-5">
                  <div className="text-wrapper-8">Courses</div>
                  <div className="text-wrapper-9">{userCourses.length}</div>
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-8">Reviews</div>
                  <div className="text-wrapper-9">{reviews_count}</div>
                </div>

                <img className="avatar" src={user.authObject.photoURL} alt="" />
              </div>
              <div className="text-wrapper-10">Statistics</div>
              <div className="text-wrapper-11">
                Welcome back, {user.authObject.displayName}!
              </div>
            </div>
            <div className="text-wrapper-12">Quick Start</div>
          </div>
          <div className="frame-7">
            {" "}
            <div className="content-container">
              <RadarComponent />
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
