import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactAudioPlayer from "react-audio-player";
import "../../quiz.css";

interface LessonAudio {
  description: string;
  lessontitle: string;
  audio: string;
}

function LessonAudio(props: LessonAudio) {
  return (
    <div className="cardquiz">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600"> </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {props.lessontitle}
        </h1>
        <div className="mt-10 max-w-2xl">
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
          <ReactAudioPlayer src={props.audio} autoPlay controls />
        </div>
      </div>
    </div>
  );
}

export default LessonAudio;
