import { UserAuth } from "../context/AuthContext";
import { Course } from "../utils/CourseClass";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { Link } from "react-router-dom";

import React, { useRef, useEffect, useState } from "react";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ReviewRequest } from "../utils/ReviewRequestClass";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import "../review.css";
export function ReviewRequests() {
  const { user, reviewRequests } = UserAuth();

  console.log(reviewRequests);
  const [search, setSearch] = useState("");

  let filteredReq: Map<string, ReviewRequest> = new Map();
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  for (const [reqId, req] of reviewRequests) {
    if (
      (user.authObject.uid == req.to || req.to === "none") &&
      req.title.includes(search)
    ) {
      filteredReq.set(reqId, req);
    }
  }
  function generateRandomLinearGradient() {
    const colors = [
      "#9CC0AC",
      "#82bda9",
      "#83accf",
      "#90bad2",
      "#bdb0b6",
      "#cf9380",
      "#83bda9",
    ];
    const color1 = colors[getRandomInt(0, colors.length - 1)];
    const color2 = colors[getRandomInt(0, colors.length - 1)];

    return `linear-gradient(232deg, ${color1} 0%, ${color2} 100%)`;
  }

  function addRandomVariation(color: any) {
    const variation = 20; // You can adjust this value for smaller or larger variation
    const r = getRandomInt(0, 255);
    const g = getRandomInt(0, 255);
    const b = getRandomInt(0, 255);

    return `rgba(${clamp(r)}, ${clamp(g)}, ${clamp(b)}, 0.85)`;
  }

  function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function clamp(value: any, min = 0, max = 255) {
    return Math.min(max, Math.max(min, value));
  }

  let reqArr = Array.from(filteredReq);

  reqArr.sort(
    ([_id1, req1], [_id2, req2]) =>
      Number(req1.reviewed) - Number(req2.reviewed)
  );

  return (
    <>
      {" "}
      <div className="review-reqests">
        <div className="framex no-scrollbar">
          {reqArr.map(([id, req]) => (
            <div
              className="cardreqests"
              style={{ background: generateRandomLinearGradient() }}
            >
              <p className="text-white text-wrap">{req.title}</p>
              <p className="text-wrapperreqests">
                {" "}
                Created at <time dateTime={req.createdAt}>{req.createdAt}</time>
              </p>{" "}
              {req.reviewed ? (
                <div className="flex items-center div-wrapperreqests  ">
                  <div className="divreqests">Reviewed</div>
                  <CheckCircleIcon
                    className={classNames("text-white", "h-6 w-6")}
                  />
                </div>
              ) : (
                <></>
              )}{" "}
              <a href={"/dashboard/review/" + id}>
                <div className="div-wrapperreqests1">
                  <div className="divreqests">View</div>
                </div>
              </a>
            </div>
          ))}
        </div>

        <div className="text-wrapper-3reqests">Review Requests</div>
        <div className="frame-8reqests">
          <div className="icon-searchreqests">
            <div className="overlap-groupreqests">
              <MagnifyingGlassIcon className="subtractreqests" />
              <img
                className="vectorreqests"
                alt="Vector"
                src="vector-109.svg"
              />
            </div>
          </div>

          <div className="text-wrapper-4reqests ">
            <input
              id="search"
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              placeholder="Search..."
              type="search"
            />{" "}
            <style>{`
            .text-wrapper-4reqests input {
              border: none;
              outline: none;
              box-shadow: none;
            }
          `}</style>
          </div>
        </div>
      </div>
    </>
  );
}
