import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  QuerySnapshot,
  arrayUnion,
} from "firebase/firestore";
import { auth, db } from "../context/firebase";
import { useState } from "react";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";

import { Course } from "./CourseClass";

export type Theme = "dark" | "light";
export type Language = "en";
export type Role = "Student";

export interface AttendedCourse {
  courseID: string;
  lessonsFinished: AttendedLesson[];
}

// workaround for firestore not supporting nested arrays
export interface QuestionAnswer {
  answers: string[];
}

export interface AttendedLesson {
  lessonID: string;
  answerID: QuestionAnswer[];
}

export interface AuthObject {
  email: string;
  uid: string;
  photoURL: string;
  displayName: string;
}

export function instanceOfAuthObject(object: any): object is AuthObject {
  const keys = ["email", "uid", "photoURL", "displayName"];
  for (let k of keys) {
    if (!(k in object)) {
      return false;
    }
  }
  return true;
}

export class BaseUser {
  preferedTheme: Theme;
  preferedLanguage: Language;
  courses: Map<string, AttendedCourse[]>;
  currentTeacher: string;
  role: Role;

  constructor(
    preferedTheme: Theme,
    preferedLanguage: Language,
    courses: Map<string, AttendedCourse[]>,
    currentTeacher: string,
    role: Role
  ) {
    this.preferedTheme = preferedTheme;
    this.preferedLanguage = preferedLanguage;
    this.courses = courses;
    this.currentTeacher = currentTeacher;
    this.role = role;
  }

  getCourseWithId(id: string) {
    if (this.courses.has(this.currentTeacher)) {
      for (const c of this.courses.get(this.currentTeacher)!) {
        if (c.courseID === id) {
          return c;
        }
      }
    }

    return null;
  }

  isAttendingCourse(courseID: string) {
    if (this.courses.has(this.currentTeacher)) {
      for (const c of this.courses.get(this.currentTeacher)!) {
        if (c.courseID === courseID) {
          return true;
        }
      }
    }
    return false;
  }

  getFinishedLessons(courseID: Course[]) {
    let atCourses = [];
    for (const c of courseID) {
      if (this.courses.has(this.currentTeacher)) {
        for (const uc of this.courses.get(this.currentTeacher)!) {
          if (c.meta.courseID === uc.courseID) {
            atCourses.push(uc);
          }
        }
      }
    }
    return atCourses;
  }

  getAttendedCourses(courses: Course[]) {
    let atCourses = [];
    if (this.courses.has(this.currentTeacher)) {
      for (const c of courses) {
        for (const uc of this.courses.get(this.currentTeacher)!) {
          if (c.meta.courseID === uc.courseID) {
            atCourses.push(c);
          }
        }
      }
    }

    return atCourses;
  }
}

class DBUser extends BaseUser {
  uid: string;

  toObject(): Record<string, any> {
    const obj: Record<string, any> = Object.assign({}, this);
    obj.courses = Object.fromEntries(this.courses.entries()); // Convert Map to an object.
    return obj;
  }

  constructor(
    uid: string,
    preferedTheme: Theme,
    preferedLanguage: Language,
    courses: Map<string, AttendedCourse[]>,
    currentTeacher: string,
    role: Role
  ) {
    super(preferedTheme, preferedLanguage, courses, currentTeacher, role);
    this.uid = uid;
  }

  static fromUser(user: User) {
    return new DBUser(
      user.authObject.uid,
      user.preferedTheme,
      user.preferedLanguage,
      user.courses,
      user.currentTeacher,
      user.role
    );
  }
}

class DBUserConverter implements FirestoreDataConverter<DBUser> {
  toFirestore(user: DBUser) {
    let obj = {
      courses: Object.fromEntries(user.courses),
      currentTeacher: user.currentTeacher,
      preferedLanguage: user.preferedLanguage,
      preferedTheme: user.preferedTheme,
      role: user.role,
      uid: user.uid,
    };
    return obj;
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions | undefined
  ): DBUser {
    const data = snapshot.data(options);
    return new DBUser(
      data.uid,
      data.preferedTheme,
      data.preferedLanguage,
      data.courses,
      data.currentTeacher,
      data.role
    );
  }
}

export class User extends BaseUser {
  // the object returned from firebase auth
  authObject: AuthObject;

  constructor(
    authObject: AuthObject,
    preferedTheme: Theme,
    preferedLanguage: Language,
    courses: Map<string, AttendedCourse[]>,
    currentTeacher: string,
    role: Role
  ) {
    super(preferedTheme, preferedLanguage, courses, currentTeacher, role);
    this.authObject = authObject;
  }

  static default() {
    return new User(
      { email: "", uid: "", photoURL: "", displayName: "" },
      "light",
      "en",
      new Map(),
      "default",
      "Student"
    );
  }

  async saveDBUser(force: Boolean = false) {
    const q = query(
      collection(db, "users"),
      where("uid", "==", this.authObject.uid)
    );
    const querySnapshot = await getDocs(q);
    // if there is no user with this id
    if (querySnapshot.size === 0 || force) {
      const newUser = doc(db, "users", this.authObject.uid).withConverter(
        new DBUserConverter()
      );
      // save the new user
      let db_user = DBUser.fromUser(this);

      const { ...raw_db_user } = db_user;

      await setDoc(newUser, raw_db_user);
    }
  }

  async enrollUserCourse(courseID: string, lessonsFinished: string[]) {
    if (!this.courses.has(this.currentTeacher)) {
      this.courses.set(this.currentTeacher, []);
    }

    let v = this.courses.get(this.currentTeacher)!;

    v.push({ courseID: courseID, lessonsFinished: [] });
    await this.saveDBUser(true);
  }

  async finishedUserLessons(courseID: string, lessonsFinished: AttendedLesson) {
    let flag: any = false;
    let course = null;

    if (this.courses.has(this.currentTeacher)) {
      let courses = this.courses.get(this.currentTeacher)!;
      for (let i = 0; i < courses.length; i++) {
        if (courseID === courses[i].courseID) {
          course = courses[i];
        }
      }
    }

    if (course == null) {
      console.log("Course not found");
      return;
    }

    for (let j = 0; j < course.lessonsFinished.length; j++) {
      if (course.lessonsFinished[j].lessonID === lessonsFinished.lessonID) {
        course.lessonsFinished[j] = lessonsFinished;
        flag = true;
        break;
      }
    }

    if (!flag) {
      course.lessonsFinished.push(lessonsFinished);
    }

    await this.saveDBUser(true);
  }

  static fromAuthObject(authObject: AuthObject) {
    return new User(authObject, "light", "en", new Map(), "default", "Student");
  }

  static fromAuthObjectAndDBUser(authObject: AuthObject, db_user: DBUser) {
    return new User(
      authObject,
      db_user.preferedTheme,
      db_user.preferedLanguage,
      new Map(Object.entries(db_user.courses)),
      db_user.currentTeacher,
      db_user.role
    );
  }

  static async getDBUser(authObject: AuthObject) {
    const q = query(
      collection(db, "users").withConverter(new DBUserConverter()),
      where("uid", "==", authObject.uid)
    );
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs;

    if (docs.length === 0) {
      return null;
    } else {
      let doc = docs[0].data();

      let user = User.fromAuthObjectAndDBUser(authObject, doc);

      return user;
    }
  }
}
