import { useParams } from "react-router-dom";

import { UserAuth } from "../context/AuthContext";
import { CourseNotFound } from "../components/CourseNotFound";
import CourseComponent from "../components/CourseComponent";

export default function MyCourseView() {
  let params = useParams();
  let { courses } = UserAuth();

  let id = params.id;
  let course = null;

  let content = <CourseNotFound />;

  for (let c of courses) {
    if (c.meta.courseID === id) {
      for (let x of c.withLanguages) {
        if (x.language === params.lang) {
          course = {
            ...c,
            withLanguages: [x], // Return only the matching withLanguages entry
          };

          break;
        }
      }
    }
  }

  if (course !== null) {
    content = <CourseComponent course={course} />;
  }

  return content;
}
