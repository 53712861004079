
import Navbar from "../components/LandingNavbar";
import { Outlet } from "react-router-dom";
import { LoggedOutProtected } from "../components/Protected";


export default function LandingLayout() {


    return (
        <>
            <LoggedOutProtected>
                <div className="h-screen">
                    <Navbar></Navbar>
                    <Outlet />

                </div>
            </LoggedOutProtected>
        </>
    )
}