import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";
import {
  HomeIcon,
  BookOpenIcon,
  PencilIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

import Notification from "./Notification";

const navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
  },
  {
    name: "Your Courses",
    href: "/dashboard/my-courses",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/pen-1@2x.png" as string,
    current: false,
  },
  {
    name: "Discover Courses",
    href: "/dashboard/courses",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
  },

  {
    name: "Milestones",
    href: "/dashboard/audio",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
  },
  {
    name: "Review Requests",
    href: "/dashboard/reviews",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
    children: {
      name: `Review`,
      href: `/dashboard/reviews/:id`,
      icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
      current: false,
    },
  },
  {
    name: "Review Responses",
    href: "/dashboard/responses",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
    children: {
      name: `Review`,
      href: `/dashboard/responses/:id`,
      icon: "https://firebasestorage.googleapis.com/v0/b/seskat-e12de.appspot.com/o/images%2FPen.png?alt=media&token=4b4ead05-3375-4c39-a70a-7af70defbfe6" as string,
      current: false,
    },
  },
  {
    name: "Review Responses",
    href: "/dashboard/responses",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/book@2x.png" as string,
    current: false,
  },
  {
    name: "Your Profile",
    href: "/dashboard/profile",
    icon: "https://c.animaapp.com/3fc6Cqeo/img/pen@2x.png" as string,
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  children?: JSX.Element;
}

const Navbar = (props: Props) => {
  const { user, logOut, userSendEmailVerification, reviewRequests } =
    UserAuth();
  const [showNotification, setShowNotification] = useState(false);
  let { userCourses } = UserAuth();

  const location = useLocation();
  let { courses } = UserAuth();
  let number: any = 0;

  if (!userCourses.length) {
    number = 0;
  }
  for (let i = 0; i < courses.length; i++) {
    user.isAttendingCourse(courses[i].meta.courseID);
  }

  for (const n of navigation) {
    n.current = false;
    if (n.href === location.pathname) {
      n.current = true;
    }
  }
  const handleSendMailVerification = async (e: any) => {
    e.preventDefault();
    await userSendEmailVerification();

    setShowNotification(true);
  };

  let validateEmail = <></>;
  if (!user.authObject.emailVerified) {
    validateEmail = (
      <div className="div-wrapper">
        <button
          onClick={handleSendMailVerification}
          className="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <div className="text-wrapper-4">Validate email</div>
        </button>
      </div>
    );
  }

  let emailNotification = <></>;
  if (showNotification) {
    emailNotification = (
      <Notification
        on_close={() => setShowNotification(false)}
        title="Notification sent!"
        subtitle="Check your email."
      ></Notification>
    );
  }
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="e-mployed-dashboard no-scroll">
        <div className="learning-wrapper">
          <div className="learning">
            <div className="frame">
              <div className="overlap-group">
                <ul className="nav-list">
                  <Link to={navigation[0].href}>
                    <li className={navigation[0].current ? "active" : ""}>
                      <span className={navigation[0].current ? "active" : ""}>
                        <HomeIcon
                          className={classNames(
                            navigation[0].current
                              ? "text-gray-500"
                              : "text-white",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {navigation[0].name}
                      </span>
                    </li>
                  </Link>
                  <Link to={navigation[2].href}>
                    <li className={navigation[2].current ? "active" : ""}>
                      <span className={navigation[2].current ? "active" : ""}>
                        <BookOpenIcon
                          className={classNames(
                            navigation[2].current
                              ? "text-gray-500"
                              : "text-white",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {navigation[2].name}
                      </span>
                    </li>
                  </Link>
                  <Link to={navigation[4].href}>
                    <li className={navigation[4].current ? "active" : ""}>
                      <span className={navigation[4].current ? "active" : ""}>
                        <PencilIcon
                          className={classNames(
                            navigation[4].current
                              ? "text-gray-500"
                              : "text-white",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {navigation[4].name}
                      </span>
                    </li>
                  </Link>{" "}
                  <Link to={navigation[5].href}>
                    <li className={navigation[5].current ? "active" : ""}>
                      <span className={navigation[5].current ? "active" : ""}>
                        <PencilIcon
                          className={classNames(
                            navigation[5].current
                              ? "text-gray-500"
                              : "text-white",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {navigation[5].name}
                      </span>
                    </li>
                  </Link>
                </ul>
                <div className="rectangle" />
                <ul className="nav-list2">
                  <Link to={navigation[7].href}>
                    <li className={navigation[7].current ? "active" : ""}>
                      <span className={navigation[7].current ? "active" : ""}>
                        <UserIcon
                          className={classNames(
                            navigation[7].current
                              ? "text-gray-500"
                              : "text-white",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {navigation[7].name}
                      </span>
                    </li>
                  </Link>
                  <Link onClick={handleSignOut} to="">
                    <li>
                      <span>
                        <ArrowLeftOnRectangleIcon
                          className={classNames("text-white", "mr-3 h-6 w-6")}
                          aria-hidden="true"
                        />
                        Log Out
                      </span>
                    </li>
                  </Link>
                </ul>

                <div className="overlap">
                  <img
                    className="img"
                    alt="Frame"
                    src="https://c.animaapp.com/3fc6Cqeo/img/frame-461.svg"
                  />
                  <img
                    className="logo"
                    alt="Logo"
                    src="https://c.animaapp.com/3fc6Cqeo/img/logo-1@2x.png"
                  />
                </div>
                {validateEmail}
              </div>
            </div>{" "}
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
      {emailNotification}
    </>
  );
};

export default Navbar;
