import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface Props {
  title: string;
  subtitle: string;
  on_close: Function;
}

export default function Notification(props: Props) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
      props.on_close();
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    setVisible(false);
    props.on_close();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      className="top-[20px] absolute w-[214px] h-[109px] top-0 right-0 bg-[#2b303980] rounded-[24px] overflow-hidden bg-cover bg-[50%_50%]"
    >
      <p className="absolute w-[415px] top-[33px] left-[70px] font-normal text-white text-[12px] tracking-[0] leading-[15.4px]">
        {props.title}
      </p>
      <p className="absolute w-[415px] top-[53px] left-[70px] font-normal text-white text-[12px] tracking-[0] leading-[15.4px]">
        {props.subtitle}
      </p>
      <img
        className="absolute w-[46px] h-[87px] top-[7px] left-[14px]"
        alt="Bell"
        src="https://c.animaapp.com/dU1CFWhx/img/bell@2x.png"
      />
      <motion.div
        initial={{ width: "100%" }}
        animate={{ width: "0%" }}
        exit={{ width: "100%" }}
        transition={{ duration: 5 }}
        className="h-2 bg-green-500 absolute bottom-0 left-0"
      />
      <button
        className="absolute top-2 right-2 text-white"
        onClick={handleClose}
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
    </motion.div>
  );
}
