import { UserAuth } from "../context/AuthContext";
import { Course } from "../utils/CourseClass";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";

import { Link } from "react-router-dom";

import React, { useRef, useEffect } from "react";
import "../course.css";

function useHorizontalScroll() {
  const elRef: any = useRef();

  useEffect(() => {
    const el = elRef.current;

    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth",
        });
      };

      el.addEventListener("wheel", onWheel);

      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  return elRef;
}

export function CoursesView() {
  let { userCourses } = UserAuth();
  let { user } = UserAuth();
  let { courses } = UserAuth();

  const scrollRef1 = useHorizontalScroll();
  const scrollRef2 = useHorizontalScroll();


  for (let i = 0; i < courses.length; i++) {
    user.isAttendingCourse(courses[i].meta.courseID);
  }

  function generateRandomLinearGradient() {
    const colors = [
      "#9CC0AC",
      "#82bda9",
      "#83accf",
      "#90bad2",
      "#bdb0b6",
      "#cf9380",
      "#83bda9",
    ];
    const color1 = colors[getRandomInt(0, colors.length - 1)];
    const color2 = colors[getRandomInt(0, colors.length - 1)];

    return `linear-gradient(232deg, ${color1} 0%, ${color2} 100%)`;
  }

  function addRandomVariation(color: any) {
    const variation = 20; // You can adjust this value for smaller or larger variation
    const r = getRandomInt(0, 255);
    const g = getRandomInt(0, 255);
    const b = getRandomInt(0, 255);

    return `rgba(${clamp(r)}, ${clamp(g)}, ${clamp(b)}, 0.85)`;
  }

  function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function clamp(value: any, min = 0, max = 255) {
    return Math.min(max, Math.max(min, value));
  }

  const filteredCourses = courses.filter((courses: any) => {
    // Check some condition here, e.g., course meets certain criteria
    // and return true if it should be included in the filtered array
    for (let i = 0; i < courses.withLanguages.length; i++) {
      for (let j = 0; j < courses.withLanguages[i].lessons.length; j++) {
        return courses.withLanguages[i].lessons[j].lessonType !== "Audio";
      }
    }
    // Change this condition as needed
  });
  return (
    <>
      <div className="courses">
        <div className="frame overflow-x-scroll" ref={scrollRef1}>
          {userCourses.map((course: Course) => (
            <Link to={"/dashboard/my-courses/en/" + course.meta.courseID}>
              {" "}
              <div key={course.meta.courseID} className=" ">
                <div
                  className="card-continue "
                  style={{ background: generateRandomLinearGradient() }}
                >
                  <img
                    className="rectangle"
                    alt="Rectangle"
                    src={course.meta.previewImage}
                  />
                  <p className="p1">{course.withLanguages[0].name}</p>
                  <p className="p">
                    {course.withLanguages[0].shortDescription}
                  </p>
                </div>
              </div>{" "}
            </Link>
          ))}
        </div>
        <div className="frame-1 overflow-x-scroll" ref={scrollRef2}>
          {filteredCourses.map((course: Course) => (
            <Link to={"/dashboard/courses/en/" + course.meta.courseID}>
              <div key={course.meta.courseID} className=" ">
                <div
                  className="card "
                  style={{ background: generateRandomLinearGradient() }}
                >
                  <img
                    className="rectangle"
                    alt="Rectangle"
                    src={course.meta.previewImage}
                  />
                  <p className="p1">{course.withLanguages[0].name}</p>
                  <p className="p">
                    {course.withLanguages[0].shortDescription}
                  </p>
                </div>
              </div>{" "}
            </Link>
          ))}
        </div>
        <div className="text-wrapper-9">Your courses</div>
        <div className="text-wrapper-10">Available courses</div>
      </div>
    </>
  );
}
