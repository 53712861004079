import { useParams } from "react-router-dom";
import ReviewResponseComponent from "../components/ReviewResponseComponent";
import { ReviewResponseNotFound } from "../components/ReviewResponseNotFound";

import { UserAuth } from '../context/AuthContext';

export default function ReviewResponseView() {
  let params = useParams();
  let { reviewResponses } = UserAuth();

  let id = params.id;
  let resp = null;

  let content = <ReviewResponseNotFound />;

  for (const [respId, r] of reviewResponses) {
    if (respId === id) {
      resp = r;
      break;
    }
  }

  if (resp !== null) {
    content = <ReviewResponseComponent resp={resp} />
  }


  return content;
}
