import "../courx.css";
import star from "../img/star 1.png";

interface Props {
  on_selected: (d: number) => void;
}

export function DificultySelector(props: Props) {
  return (
    <>
      <ul className="grid w-full gap-2 md:grid-cols-3 ml-10 mr-10">

        <li className="cardstar h-64 w-64 mt-56" key={0}>
          <input
            type="radio"
            onChange={() => {
              props.on_selected(0);
            }}
            name="hosting"
            value="easy"
            id="easy"
            required
          />{" "}
          <label className="text-center" htmlFor="easy">
            <div className="mt-10">
              <img className="justify-center mx-auto w-24 h-24" alt="Star" src={star} />
              <div className="mt-10 text-white text-3xl">Easy</div>
            </div>
          </label>
        </li>
        <li className="cardstar h-64 w-64 mt-56" key={1}>
          <input
            type="radio"
            onChange={() => {
              props.on_selected(1);
            }}
            name="hosting"
            value="medium"
            id="medium"
            required
          />{" "}
          <label className="text-center" htmlFor="medium">
            <div className="grid mt-10 w-full gap-2 md:grid-cols-2">
              <img className=" w-24 h-24" alt="Star" src={star} />
              <img className=" w-24 h-24" alt="Star" src={star} />
            </div>
            <div className="mt-10 text-white text-3xl">Medium</div>
          </label>
        </li>
        <li className="cardstar h-64 w-64 mt-56" key={2}>
          <input
            type="radio"
            onChange={() => {
              props.on_selected(2);
            }}
            name="hard"
            value="hard"
            id="hard"
            required
          />{" "}
          <label className="text-center" htmlFor="hard">
            <div className="grid mt-10 w-full md:grid-cols-3">
              <img className="w-24 h-24" alt="Star" src={star} />
              <img className="w-24 h-24" alt="Star" src={star} />{" "}
              <img className="w-24 h-24" alt="Star" src={star} />
            </div>
            <div className="mt-10 text-white text-3xl">Hard</div>
          </label>
        </li>
      </ul >
    </>
  );
}
