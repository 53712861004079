import React from 'react';
import { UserAuth } from '../context/AuthContext';
import Navbar from '../components/DashboardNavbar';
const Account = () => {
  const { logOut, user } = UserAuth();


  return (
    <>
      <div className='w-[300px] m-auto'>
        <h1 className='text-center text-2xl font-bold pt-12'>Account</h1>
        <div>
          <p>Welcome, {user.displayName ? user.displayName : user.email}</p>
        </div>

      </div>
    </>

  );
};

export default Account;
