import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { collection, doc, updateDoc, getDoc, getDocs, query, where, QuerySnapshot, arrayUnion } from "firebase/firestore";
import { auth, db } from '../context/firebase';

export class ReviewRequest {
  from: string;
  to: string;
  title: string;
  message: string;
  courseID: string;
  lessonID: string;
  resource: string;
  reviewed: boolean;
  createdAt: string;
  responseID: string;

  constructor(from: string, to: string, title: string, message: string, courseID: string, lessonID: string, resource: string, reviewed: boolean, createdAt: string, responseID: string) {
    this.from = from;
    this.to = to;
    this.title = title;
    this.message = message;
    this.courseID = courseID;
    this.lessonID = lessonID;
    this.resource = resource;
    this.reviewed = reviewed;
    this.createdAt = createdAt;
    this.responseID = responseID;
  }

  static async markReviewed(id: string) {
    const r = doc(db, "review_requests", id);
    await updateDoc(r, { reviewed: true });
  }
}

export class ReviewRequestConverter implements FirestoreDataConverter<ReviewRequest> {

  toFirestore(course: WithFieldValue<ReviewRequest>) {
    return course;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions | undefined): ReviewRequest {
    const data = snapshot.data(options);
    return new ReviewRequest(data.from, data.to, data.title, data.message, data.courseID, data.lessonID, data.resource, data.reviewed, data.createdAt, data.responseID)
  }
}
