import { ChangeEvent, useEffect, useRef, useState } from "react";
import Chart, {
  Filler,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js/auto";
import { UserAuth } from "../context/AuthContext";
import Notification from "../components/Notification";
import { CourseTags } from "../utils/CourseClass";

export default function RadarComponent() {
  const {
    user,
    courses,
    logOut,
    uploadFile,
    updateUser,
    userSendPasswordReset,
    userCourses,
  } = UserAuth();

  const max_per_skill_group = 10;
  const canvas_ref = useRef(null);
  const pdf_canvas_ref = useRef(null);

  const get_canvas_config: any = function (
    labels: string[],
    data: Number[],
    max: Number
  ) {
    const d = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: data,
          fill: true,
          backgroundColor: "rgba(204, 255, 204, 0.2)",
          borderColor: "rgb(0, 153, 51)",
          pointBackgroundColor: "rgb(255, 99, 132)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(255, 99, 132)",
        },
      ],
    };
    const config = {
      type: "radar",
      data: d,
      options: {
        maintainAspectRatio: true,

        responsive: true,

        plugins: {
          legend: {
            display: false,
            onClick: null,
          },

          tooltips: {
            enabled: false,
          },
        },

        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: max,
            ticks: {
              display: false, // Hides the labels in the middel (numbers)
            },
          },
        },

        elements: {
          line: {
            borderWidth: 3,
          },
        },
      },
    };
    return config;
  };

  useEffect(() => {
    const canvas = canvas_ref.current!;

    let data: Map<string, Number> = new Map();

    for (let i = 0; i < CourseTags.length; i++) {
      data.set(CourseTags[i], 0);
    }

    const get_course_with_id = (id: string) => {
      for (let c of courses) {
        if (c.meta.courseID === id) {
          return c;
        }
      }
      // should never happen
      return null;
    };

    let userLessonsCourse = user.getFinishedLessons(courses);

    let points = new Array(CourseTags.length).fill(0);
    let max_points = new Array(CourseTags.length).fill(0);
    for (let course of userLessonsCourse) {
      let c = get_course_with_id(course.courseID);
      // todo, fix when adding languages

      for (let lesson of c.withLanguages[0].lessons) {
        for (let finished_lesson of course.lessonsFinished) {
          if (lesson.lessonID === finished_lesson.lessonID) {
            if (lesson.lessonType == "Quiz") {
              let q = lesson.resource.quiz;

              for (let i = 0; i < q.length; i++) {
                for (let j = 0; j < CourseTags.length; j++) {
                  max_points[j] += q[i].tagsPoints[j];

                  if (
                    finished_lesson.answerID[i].answers[0] ===
                    q[i].correctAnswer
                  ) {
                    points[j] += q[i].tagsPoints[j];
                  }
                }
              }
            } else if (
              lesson.lessonType == "Text" &&
              lesson.resource.source === "true"
            ) {
              for (let i = 0; i < CourseTags.length; i++) {
                points[i] += lesson.resource.points[i];
              }
            }
          }
        }
      }
    }

    for (let i = 0; i < CourseTags.length; i++) {
      data.set(CourseTags[i], points[i]);
    }

    let keys: string[] = [];
    let values: Number[] = [];

    let max = 0;
    for (let m of max_points) {
      if (m > max) {
        m = max;
      }
    }

    for (let [key, value] of data) {
      keys.push(key);
      values.push(value);
    }

    new Chart(canvas, get_canvas_config(keys, values, max));
  });

  return <canvas id="radar" ref={canvas_ref}></canvas>;
}
