import { useParams } from "react-router-dom";

import { UserAuth } from "../context/AuthContext";
import { CourseNotFound } from "../components/CourseNotFound";
import EnrollCourseComponent from "../components/EnrollCourseComponent";

export default function CourseView() {
  let params = useParams();
  let { courses } = UserAuth();

  let id = params.id;
  let course = null;

  let content = <CourseNotFound />;
  let lang = params.lang;
  if (lang === null || lang === undefined) {
    lang = "en";
  }

  for (let c of courses) {
    if (c.meta.courseID === id) {
      for (let x of c.withLanguages) {
        console.log(x.language);
        if (x.language === lang) {
          course = {
            ...c,
            withLanguages: [x], // Return only the matching withLanguages entry
          };

          break;
        }
      }
    }
  }

  if (course !== null) {
    content = <EnrollCourseComponent lang={lang} course={course} />;
  }

  return content;
}
