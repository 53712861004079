import { Lessons } from "./LessonClass"

import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";

export type Category = "People and Group Management" | "";
export type Language = "en";

let CourseTags = ["Problem Solving", "Self-Awareness", "Empathy", "Communication", "Active Listening", "Organisation Skills", "Resilience", "Digital Skills", "Teamwork", "Creativity"]

export { CourseTags };

export interface CourseMeta {
    courseID: string;
    category: Category;
    previewImage: string;
    creationTimestamp: string;
    creatorID: string;
}

export interface CourseWithLang {
    language: Language;
    name: string;
    description: string;
    lessons: Lessons;
    shortDescription: string;
}

export class Course {
    meta: CourseMeta;
    withLanguages: CourseWithLang[];

    constructor(meta: CourseMeta, withLanguages: CourseWithLang[]) {
        this.meta = meta;
        this.withLanguages = withLanguages;
    }
}

export class CourseConverter implements FirestoreDataConverter<Course> {

    toFirestore(course: WithFieldValue<Course>) {
        return course;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions | undefined): Course {
        const data = snapshot.data(options);
        return new Course(data.meta, data.withLanguages)
    }
}
