import { ReviewRequest } from "../utils/ReviewRequestClass";
import { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { ReviewResponse } from "../utils/ReviewResponseClass";
import "../response.css";
import Notification from "./Notification";

interface Props {
  resp: ReviewResponse;
}
export default function ReviewResponsesComponent(props: Props) {
  return (
    <>
      <div className="response-writer">
        <div className="text-wrapper">Review Requests</div>

        <div className="cardresponse">
          <p className="title">{props.resp.title}</p>{" "}
          <textarea
            placeholder="Your review here.."
            disabled
            value={props.resp.message}
            id="large-input"
            rows={5}
            className="div-wrapper"
          ></textarea>
          <div className="frame-3">
            <div className="text-wrapper-5">
              <a
                href={props.resp.resource}
                download={props.resp.title}
                target="_blank"
              >
                <button>Download submission</button>
              </a>
            </div>
          </div>{" "}
        </div>
      </div>{" "}
    </>
  );
}
